import React, { useState } from "react";
import TabsLayout from "./subComp/TabsLayout";
import useMobile from "./useMobile";
import AppSettings_Profile from "./AppSettingsTabs/AppSettings_Profile";
import AppSettings_Account from "./AppSettingsTabs/AppSettings_Account";
import AppSettings_Notifications from "./AppSettingsTabs/AppSettings_Notifications";
import AppSettings_Settings from "./AppSettingsTabs/AppSettings_Settings";
import NavBar from "./NavBar";

export default function AppSettings() {
  const { isMobile } = useMobile();
  return (
    <div className={``}>
           <NavBar/>
      <TabsLayout tabs={["Profile", "Account", "Notifications", "Settings"]}>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <AppSettings_Profile />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <AppSettings_Account />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <AppSettings_Notifications />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <AppSettings_Settings />
        </div>
      </TabsLayout>
    </div>
  );
}

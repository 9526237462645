import React from "react";
import { useRef, useState, useEffect } from "react";
import OptionsMenu from "./OptionsMenu";
import EventCard from "../EventCard";
import Icon from "./Icon";
import { editEvent, deleteEvent } from "../../tools/general";
import useMobile from "../useMobile";
import ScrollView from "../ScrollView";
import { useNavigate } from "react-router-dom";

export default function RowEvent(props) {
  const { isMobile } = useMobile();
  const { event, setEvents,setTypeBoard,setEditEvent } = props;
  console.log("setEvents");
  console.log(setEvents);
  const navigate = useNavigate()
  const dropObj = [
    {
      dropOption: "Edit Event",
      actionFunc: () => editEvent(event, setEditEvent,setTypeBoard,navigate),
      iconName: "edit",
    },
    {
      dropOption: "Delete Event",
      actionFunc: () => deleteEvent(event.event_id, setEvents),
      iconName: "delete",
    },
  ];

  return (
<div 
      className={`flex flex-col w-[100%] gap-2 pt-4`}
>

      <div
        className={`w-full h-[30px] ${
          !isMobile ? "px-[210px]" : "px-4"
        } flex flex-row items-center  justify-between`}
      >
        <h5 className=" text-gray-800">{event.event_name}</h5>
        <div className="relative w-[25px]  flex justify-center items-center h-[25px]">
          <OptionsMenu dropObj={dropObj} color={"black"} />
        </div>
      </div>

      <ScrollView flexDirection={"row"}>
      <div
        className={`w-full ${
          !isMobile ? "px-[210px]" : "px-4"
        } py-2  flex flex-row gap-4 flex-1`}
      >

        {event.rooms.length > 0 &&
          event.rooms.map((room, index) => {
            return (
              <EventCard
              setTypeBoard={setTypeBoard}
                key={index}
                event_id={event.event_id}
                room={room}
                index={index}
                setEvents={setEvents}
              />
            );
          })}
      </div>
      </ScrollView>
</div>


  );
}

import React, { useState } from "react";

const Switch = ({ label, initialChecked = false }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="flex items-center space-x-3">
      {label && (
        <span className="text-sm font-medium text-gray-700">{label}</span>
      )}

      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={handleChange}
        />
        <span
          className={`w-12 h-6 rounded-full flex items-center transition-all duration-300 ${
            checked ? "bg-green-500" : "bg-gray-400"
          }`}
        >
          <span
            className={`${
              checked ? "translate-x-6" : "translate-x-1"
            } inline-block w-5 h-5 bg-white rounded-full shadow-md transition-transform`}
          />
        </span>
      </label>

      <span
        className={`text-sm font-medium ${
          checked ? "text-green-500" : "text-gray-600"
        }`}
      >
        {checked ? "On" : "Off"}
      </span>
    </div>
  );
};

export default Switch;

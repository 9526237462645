import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFeedData } from "../services";

import Post from "./Post";
import Layout from "./Layout";
import Icon from "./subComp/Icon";
import Container from "./Container";

export default function Feed(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  const { event_id, room_id } = useParams();

  useEffect(() => {
    // const ws = new WebSocket('wss://avigamliel-backend.xyz');
    const ws = new WebSocket(
      `${window.location.protocol == "http:" ? "ws" : "wss"}://${
        window.location.host
      }`
    );
    ws.onopen = () => {
      if (!location.state) {
        if (!sessionStorage.getItem("ef-auth")) {
          setError("Not Authorize");
        } else {
          ws.send(
            JSON.stringify({
              userId: sessionStorage.getItem("ef-auth"),
              eventId: event_id,
              roomId: room_id,
            })
          );
        }
      } else {
        sessionStorage.setItem("ef-auth", location.state.userId);
        ws.send(
          JSON.stringify({
            userId: location.state.userId,
            eventId: event_id,
            roomId: room_id,
          })
        );
      }
      //     if(sessionStorage.getItem('auth'))
      //     setError("Not Authorize")
      // } else {
      //     ws.send(JSON.stringify({ userId: location.state.userId, eventId: event_id, roomId: room_id }));
      // }
    };

    // Handle incoming messages
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log("setImages");
      setImages((prev) => [data.file, ...prev]);
    };

    // Handle connection close
    ws.onclose = () => {
      console.log("Disconnected from the WebSocket server");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    const run = async () => {
      try {
        const res = await getFeedData(room_id, localStorage.getItem("token"));
        console.log(res);
        if (res.status == 200) {
          setImages(res.data.data);
        } else {
          console.log("else in Feed");
          console.log(res);
        }
      } catch (error) {
        console.log("failed in Feed");
        console.log(error);
      }
    };
    run();
  }, []);

  // bg-white/50 backdrop-blur-md

  return (
    <div className="flex
    flex-col justify-start items-center w-[100%] py-4 px-4 h-[100%] gap-2 overflow-scroll">
      <div className="absolute top-0
      left-0 w-[100%] z-20 gap-4 bg-white  py-4 px-4 bg-white text-gray-800 flex flex-col justify-between items-center">
       <Container dir="row" className="justify-between">
        <span
          className="text-gray-800"
          onClick={() =>
            navigate("/", {
              state: { room_id, event_id },
            })
          }
        >
          Logo
        </span>
        <h1 className="text-xl">Avi and Lital Wedding</h1>
        <img
                        class="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                    />
       </Container>
       <div className="z-10  w-full gap-3 flex justify-center flex-row">

<span>Gallery</span>
<span className="text-primary-600 font-bold">Feed</span>
</div>
      </div>
     
      <div 
      onClick={()=>{navigate("/camera", { state: { room_id, event_id } })}}
      className="absolute bottom-[80px] z-20 w-[70px] flex justify-center items-center bg-primary-600 text-white rounded-[70px] h-[70px]">
        <Icon name="camera" size={30} color="white" />
      </div>
      {/* <Layout event_  id={event_id} room_id={room_id} /> */}
      {/* <a href={`${window.location.pathname.split('/').slice(0, -1).join("/")}/camera`}>camera</a> */}
      {error ? (
        <div>Not authorize</div>
      ) : (
        images.length > 0 &&
        images?.map((image, index) => {
          if (image) {
            return (
              <Post
                key={`post-${index}`}
                image={image}
                eventId={event_id}
                roomId={room_id}
              />
            );
          } else {
            return null;
          }
        })
      )}
    </div>
  );
}

import useMobile from "./useMobile"

export function ContainerLayout(props) {
    const {dir, className=""} = props
    const { isMobile } = useMobile()
    return (
        <div className={`${className} flex ${dir=="row"? "flex-row":"flex-col"}  ${isMobile ? "px-4" : "px-[210px]"}  w-full `}>
            {props.children}
        </div>
    )
}
export default function Container(props) {
    const {dir, scroll,className} = props
    console.log(className)
    return (
        <div className={`${className??'relative' }  flex ${dir == 'row'?'flex-row': 'flex-col' } w-full h-full  `}>
            {props.children}
        </div>
    )
}
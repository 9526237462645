import React, { useState } from "react";
import Icon from "./Icon";

export default function InputText(props) {
  const { name, textRef, onChange, onBlur, disabled = false, placeholder='' } = props;
  console.log(onChange, name,onBlur)
  return (
    <div className="relative w-[100%]">
      <input
        ref={textRef}
        id={name}
        name={name}
        placeholder={placeholder}
        type="text"
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="text"
        required
        disabled={disabled}
        className="block w-full rounded-md border-0 py-2 px-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
        style={{
          outline: `2px solid ${
            textRef?.current?.isValidate
              ? "green"
              : textRef?.current?.isValidate === false
              ? "red"
              : null
          }`,
        }}
      />
    </div>
  );
}

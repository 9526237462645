import { useEffect, useState } from "react";
import { getEventData } from "../services";
import Icon from "./subComp/Icon";
import { useNavigate } from "react-router-dom";
import EventCard from "./EventCard";
import RowEvent from "./subComp/RowEvent";
import useMobile from "./useMobile";
import TabsLayout from "./subComp/TabsLayout";
import Container from "./Container";
import ScrollView from "./ScrollView";
const img = require('./pestival.png')
export default function Events({
  events,
  setEvents,
  setType,
  setTypeBoard,
  setEditEvent,
}) {
  const navigate = useNavigate();
  const [typeEvents, setTypeEvents] = useState("my-events");

  const [myEvents, setMyEvents] = useState([]);
  const [historyEvents, setHistoryEvents] = useState([]);
  const { isMobile } = useMobile();

  useEffect(() => {
    // get events data

    const run = async () => {
      try {
        let resultData;
        if (typeEvents == "my-events" && myEvents.length == 0) {
          resultData = await getEventData(localStorage.getItem("token"));
          // new data
        } else if (typeEvents == "events" && events.length == 0) {
        } else if (typeEvents == "history" && historyEvents.length == 0) {
        }
        const events_ = [];
        console.log(resultData);

        if (resultData.status == 200) {
          resultData.data.data.forEach((element) => {
            const checkExist = events_.findIndex(
              (p) => p.event_id == element.event_id
            );
            if (checkExist !== -1) {
              events_[checkExist].rooms.push({
                room_name: element.room_name,
                room_active: element.room_active,
                room_id: element.room_id,
                room_max_guest: element.room_max_guest,
              });
            } else {
              events_.push({
                event_id: element.event_id,
                event_name: element.event_name,
                rooms: [
                  {
                    room_name: element.room_name,
                    room_active: element.room_active,
                    room_id: element.room_id,
                    room_max_guest: element.room_max_guest,
                  },
                ],
              });
            }
          });
          if (typeEvents == "my-events") setMyEvents(events_);
          if (typeEvents == "events") setEvents(events_);
          if (typeEvents == "history") setHistoryEvents(events_);
        } else {
          console.log("else in run");
          console.log(resultData);
        }
      } catch (err) {
        console.log("failed");
        if (err.status == 401) {
          navigate("/login", { replace: true })
        }
        console.log(err);
      }
    };

    run();
  }, [typeEvents, events]);
  return (
    // <div
    //   className={`flex flex-col w-[100%] h-full  gap-4`}
    // >
    <Container>
      <TabsLayout tabs={["Event", "My Event", "History"]}>

        {/* <div className="w-full flex flex-col  gap-4  pb-8 overflow-auto"
          style={{ height: 'calc(100vh - 100px)' }}
        > */}
        <Container dir="col" className={'absolute top-[50px]'} scroll={true}>
            {events.length == 0 &&
              <div className="w-full h-full flex flex-col py-32 gap-4 justify-center items-center bg-white">
                <img src={'./pestival.png'} style={{ transform: 'scale(1)', opacity: 0.3 }} width={300} />
                <h3 className="text-2xl text-gray-300">There is not content</h3>
              </div>
            }
                <ScrollView flexDirection="col">
            {events?.map((event, index) => {
              return (
                <RowEvent
                  setEditEvent={setEditEvent}
                  event={event}
                  setEvents={setEvents}
                  setTypeBoard={setTypeBoard}
                />
              );
            })}
          </ScrollView>
        </Container>
        {/* </div> */}
        {/* <div className="w-full flex flex-col  gap-4  pb-8 overflow-auto"
          style={{ height: 'calc(100vh - 100px)' }}
        > */}

<Container dir="col" className={'absolute top-[50px]'} scroll={true}>
{myEvents.length == 0 &&
              <div className="w-full h-full flex flex-col py-32 gap-4 justify-center items-center bg-white">
                <img src={'./pestival.png'} style={{ transform: 'scale(1)', opacity: 0.3 }} width={300} />
                <h3 className="text-2xl text-gray-300">There is not content</h3>
              </div>
            }
          <ScrollView flexDirection="col">
            {[...myEvents, ...myEvents, ...myEvents, ...myEvents]?.map((event, index) => {
              return (
                <RowEvent
                  setEditEvent={setEditEvent}
                  event={event}
                  setEvents={setMyEvents}
                  setTypeBoard={setTypeBoard}
                />
              );
            })}
          </ScrollView>

        </Container>

        {/* </div> */}
        {/* <div className="w-full flex flex-col  gap-4  pb-8 overflow-auto"
          style={{ height: 'calc(100vh - 100px)' }}
        > */}
<Container dir="col" className={'absolute top-[50px]'} scroll={true}>
          
            {historyEvents.length == 0 &&
              <div className="w-full h-full flex flex-col py-32 gap-4 justify-center items-center bg-white">
                <img src={'./pestival.png'} style={{ transform: 'scale(1)', opacity: 0.3 }} width={300} />
                <h3 className="text-2xl text-gray-300">There is not content</h3>
              </div>
            }
                    <ScrollView flexDirection="col">

          {historyEvents?.map((event, index) => {
            return (
              <RowEvent
                setEditEvent={setEditEvent}
                event={event}
                setEvents={setHistoryEvents}
                setTypeBoard={setTypeBoard}
              />
            );
          })}
                    </ScrollView>
</Container>
      </TabsLayout>
    </Container>

    // </div>

  );
}

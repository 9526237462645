import React from "react";
import { useEffect, useState, useRef } from "react";
import Icon from "./subComp/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../services";
import InputPassword from "./subComp/InputPassword";
import { validatePassword } from "../tools/general";

function ResetPassword(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);

  console.log(location.search);

  // PASSWORD management
  const onChangePassword = (e) => {
    // console.log(
    //   "onChangePassword: ",
    //   e.target.value,
    //   validatePassword(e.target.value)
    // );
  };

  const onBlurPassword = (e) => {
    console.log(
      "onBlurPassword: ",
      e.target.value,
      validatePassword(e.target.value)
    );
  };

  // RE PASSWORD management
  const onChangeRePassword = (e) => {
    // console.log(
    //   "onChangeRePassword: ",
    //   e.target.value,
    //   validatePassword(e.target.value)
    // );
  };

  const onBlurRePassword = (e) => {
    console.log(
      "onBlurRePassword: ",
      e.target.value,
      validatePassword(e.target.value)
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log(location);
    console.log("reset password submit");
    const { password, repassword } = e.target;
    console.log(password.value, repassword.value);

    // submit check passwords mach
    if (repassword.value !== password.value) {
      passwordRef.current.isValidate = false;
      rePasswordRef.current.isValidate = false;
      return setMsgToUser({ color: "red", msg: "Passwords dont match" });
    } else {
      setMsgToUser({ color: "green", msg: "" });
    }

    // submit check password strong
    passwordRef.current.isValidate = validatePassword(password.value);
    console.log(passwordRef.current.isValidate);
    if (!passwordRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Password must have 6-8 chars. at least one number and one special character.",
      });

    // submit check Repassword strong
    rePasswordRef.current.isValidate = validatePassword(repassword.value);
    console.log(rePasswordRef.current.isValidate);
    if (!rePasswordRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Password must have 6-8 chars. at least one number and one special character.",
      });

    // פה צריך שליחה לשרת לצורך שינוי סיסמה בדאטה בייס ליוזר
    // אם לא הצליח - להחזיר הודעה מהסרבר לנציג אותה ליוזר
    // אם הצליח - הודעה ליוזר והעברה ללוגין פייג

    // pass tests - try to send
    console.log("Try to send for Reset password..");

    try {
      const res = await resetPassword(password.value, location.search);
      console.log(res);
      console.log(res.status);
      if (res.status == 200) {
        console.log("success");
        console.log(res.data.data.message);
        setMsgToUser({
          color: "green",
          msg: "Your password has been reset. moving to Login page",
        });
        navigate("/login");
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: res.data.data.message });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
      setMsgToUser({
        color: "red",
        msg:
          err.name == "AxiosError"
            ? err.response.data.message
            : "Process failed. Try agian",
      });
    }
  };

  // useEffect(() => {
  //   if (!msgToUser) return;
  //   const to = setTimeout(() => {
  //     setMsgToUser(null);
  //   }, 3000);
  //   return () => clearTimeout(to);
  // }, [msgToUser]);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=primary&shade=500"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-1 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          <div>ResetPassword</div>
        </h2>
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
        <form onSubmit={onSubmit} className="space-y-2">
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-1">
              <InputPassword
                name={"password"}
                passwordRef={passwordRef}
                onChange={onChangePassword}
                onBlur={onBlurPassword}
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                rePassword
              </label>
            </div>
            <div>
              <InputPassword
                name={"repassword"}
                passwordRef={rePasswordRef}
                onChange={onChangeRePassword}
                onBlur={onBlurRePassword}
              />
            </div>
          </div>
          {msgToUser ? (
            <div className="text-center" style={{ color: msgToUser.color }}>
              {msgToUser.msg}
            </div>
          ) : null}
          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            >
              Reset My Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;

export default function Row({ item }) {
    const { type, sum, count } = item;
    return (
      <li class="flex py-6">
        <div class="ml-4 flex flex-1 flex-col">
          <div>
            <div class="flex justify-between text-base font-medium text-gray-900">
              <h3>
                <a href="#">{type}</a>
              </h3>
              <p class="ml-4">${sum}</p>
            </div>
            <p class="mt-1 text-sm text-gray-500">Regular</p>
          </div>
          <div class="flex flex-1 items-end justify-between text-sm">
            <p class="text-gray-500">Qty {count}</p>
  
         
          </div>
        </div>
      </li>
    );
  }
  
import {
  BsBrush,
  BsCursorFill,
  BsEmojiGrin,
  BsQrCodeScan,
} from "react-icons/bs";
import { FaRegSmile, FaUser, FaSearch, FaMarker } from "react-icons/fa";
import { FaCropSimple, FaRegBuilding, FaRegLightbulb } from "react-icons/fa6";
import {
  IoIosFootball,
  IoIosSettings,
  IoMdDownload,
  IoIosTimer,
  IoMdArrowRoundBack,
} from "react-icons/io";
import {
  IoCameraOutline,
  IoFastFoodOutline,
  IoHome,
  IoFlashOutline,

  IoShareSocialOutline,
} from "react-icons/io5";
import { ImCross } from "react-icons/im";
import {
  MdDeleteOutline,
  MdEmojiSymbols,
  MdOutlineAttachMoney,
  MdFileUpload,
  MdOutlineAdd,
  MdNavigateNext,
  MdOutlineQuestionMark,
  MdOutlineAspectRatio 
} from "react-icons/md";
import {
  PiDog,
  PiSignIn,
  PiStickerFill,
  PiTextTBold,
  PiEnvelopeOpen,
} from "react-icons/pi";
import { AiOutlineSave } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { GrGallery } from "react-icons/gr";
import { RiGalleryView } from "react-icons/ri";
import { LuLink } from "react-icons/lu";
import { BsQrCode, BsCartCheckFill, BsPeopleFill } from "react-icons/bs";
import { SlOptions } from "react-icons/sl";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoSearch } from "react-icons/go";
import { GrSearch } from "react-icons/gr";

import { LiaFileContractSolid } from "react-icons/lia";
import { BiWorld } from "react-icons/bi";
import { PiSignOutFill } from "react-icons/pi";
import { MdRoundaboutRight } from "react-icons/md";
import { RxAccessibility } from "react-icons/rx";

import { RiUploadCloud2Fill } from "react-icons/ri";


<FaUser />;
export default function Icon(props) {
  const { name, size, color, choose, onClick } = props;
  const colorChoose = "red";
  return (
    <>
      {name == "delete" && (
        <MdDeleteOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      
      {name == "flash" && (
        <IoFlashOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
         {name == "timer" && (
        <IoIosTimer
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      
          {name == "aspect-ratio" && (
        <MdOutlineAspectRatio
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      
      {name == "plus" && (
        <MdOutlineAdd
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "crop" && (
        <FaCropSimple
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "emoji" && (
        <BsEmojiGrin
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "text" && (
        <PiTextTBold
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "sticker" && (
        <PiStickerFill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "smile" && (
        <FaRegSmile
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "brush" && (
        <BsBrush
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "upload" && (
        <MdFileUpload
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />

      )} 
           {name == "upload-cloud" && (
        <RiUploadCloud2Fill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )} 
      

      {name == "prices" && (
        <MdOutlineAttachMoney
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "register" && (
        <FaMarker
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "question" && (
        <MdOutlineQuestionMark
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "dog" && (
        <PiDog
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "login" && (
        <PiSignIn
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "building" && (
        <FaRegBuilding
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "football" && (
        <IoIosFootball
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "lightbulb" && (
        <FaRegLightbulb
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "food" && (
        <IoFastFoodOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {/* {name == 'emoji' && <MdEmojiSymbols size={size} color={choose? colorChoose:color} onClick={onClick} />} */}
      {name == "outline" && (
        <MdOutlineAdd
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "move" && (
        <BsCursorFill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "save" && (
        <AiOutlineSave
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "discard" && (
        <ImCross
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "back" && (
        <IoMdArrowRoundBack
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "next" && (
        <MdNavigateNext
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "camera" && (
        <IoCameraOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "edit" && (
        <CiEdit
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "share" && (
        <IoShareSocialOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "home" && (
        <IoHome
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "settings" && (
        <IoIosSettings
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "scaner" && (
        <BsQrCodeScan
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "eye-close" && (
        <IoEyeOffOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "eye-open" && (
        <IoEyeOutline
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "galleryView" && (
        <RiGalleryView
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "Gallery" && (
        <GrGallery
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "link" && (
        <LuLink
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}{" "}
      {name == "qrCode" && (
        <BsQrCode
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "options" && (
        <SlOptions
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "menu-hamburger" && (
        <RxHamburgerMenu
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "invite" && (
        <PiEnvelopeOpen
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "search" && (
        <FaSearch
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "profile" && (
        <FaUser
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "people" && (
        <BsPeopleFill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "shop" && (
        <BsCartCheckFill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "download" && (
        <IoMdDownload
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "logout" && (
        <PiSignOutFill
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "lang" && (
        <BiWorld
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "terms" && (
        <LiaFileContractSolid
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "about" && (
        <MdRoundaboutRight
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
      {name == "negishut" && (
        <RxAccessibility
          size={size}
          color={choose ? colorChoose : color}
          onClick={onClick}
        />
      )}
    </>
  );
}

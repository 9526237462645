import React, { useState } from "react";
import TabsLayout from "./subComp/TabsLayout";
import useMobile from "./useMobile";
import EditRoom_Settings from "./EditRoomTabs/EditRoom_Settings";
import EditRoom_GuestList from "./EditRoomTabs/EditRoom_GuestList";
import EditRoom_Display from "./EditRoomTabs/EditRoom_Display";
import EditRoom_UploadFiles from "./EditRoomTabs/EditRoom_UploadFiles";
import EditRoom_Theme from "./EditRoomTabs/EditRoom_Theme";

export default function EditRoom() {
  const { isMobile } = useMobile();
  return (
    <div className={``}>
      <TabsLayout
        tabs={[
          "Room Settings",
          "Guest List",
          "Display",
          "Upload Files",
          "Theme",
        ]}
      >
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <EditRoom_Settings />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <EditRoom_GuestList />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <EditRoom_Display />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <EditRoom_UploadFiles />
        </div>
        <div
          className={`w-full ${
            !isMobile ? "px-[210px]" : "px-4"
          } flex flex-row items-center`}
        >
          <EditRoom_Theme />
        </div>
      </TabsLayout>
    </div>
  );
}

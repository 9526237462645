import { loginUser, connectGoogle } from "../services";
import { useNavigate } from "react-router-dom";
import { Grid } from 'react-loader-spinner'
// import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import Icon from "./subComp/Icon";
import ForgotPassword from "./ForgotPassword";
import ModalContainer from "./subComp/ModalContainer";
import BackButton from "./BackButton";
import Layout from "./Layout";
import useMobile from "./useMobile";
import InputPassword from "./subComp/InputPassword";
import InpuEmail from "./subComp/InpuEmail";
import { validateEmail } from "../tools/general";
import Logo from "./subComp/Logo";

export default function Login() {
  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [modalToggle, setModalToggle] = useState(false);
  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
 
  // Google login func
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse: ", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  // MAIL management
  const onChangeMail = (e) => {
    // console.log(
    //   "onChangeMail: ",
    //   e.target.value,
    //   validateEmail(e.target.value)
    // );
  };

  const onBlurMail = (e) => {
    console.log("onBlurEmail: ", e.target.value, validateEmail(e.target.value));
  };

  // PASSWORD management
  const onChangePassword = (e) => {
    // console.log(
    //   "onChangePassword: ",
    //   e.target.value,
    // );
  };
  // useEffect(()=>{
  //   if(localStorage.getItem("token")){
  //     navigate("/dashboard")
  //   }
  // },[])

  const onBlurPassword = (e) => {
    console.log("onBlurPassword: ", e.target.value);
  };

  // ONSUBMIT management
  const onSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = e.target;
    console.log(email.value, password.value);

    // submit check mail
    emailRef.current.isValidate = validateEmail(email.value);
    console.log(emailRef.current.isValidate);
    if (!emailRef.current.isValidate) {
      return setMsgToUser({
        color: "red",
        msg: "Not a valid mail.",
      });
    } else {
      setMsgToUser({
        color: "green",
        msg: "",
      });
    }

    // pass tests - try to send
    console.log("Try to Login..");

    try {
      const res = await loginUser(email.value, password.value);
      console.log(res);
      console.log(res.status);
      if (res.status == 200) {
        console.log("success");
        console.log(res.data.data.message);
        localStorage.setItem("token", res.data.data.token);
        setMsgToUser({
          color: "green",
          msg: res.data.data.message,
        });
        navigate("/dashboard");
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: res.data.data.message });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);

      setMsgToUser({
        color: "red",
        msg:
          err.name == "AxiosError"
            ? err.response.data.message
            : "Process failed. Try agian",
      });
    }
  };

  // Log Out func
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  useEffect(() => {
    if (user.length >0) {
      console.log("user: ", user);

      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              // Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          setProfile(res.data);
          const obj = {
            email: res.data.email,
            family_name: res.data.family_name,
            given_name: res.data.given_name,
            id: res.data.id,
            name: res.data.name,
            picture: res.data.picture,
            verified_email: res.data.verified_email,
          };
          // send to server to check user exit if not save the data and to get token //
          const user_ = await connectGoogle(obj);
          console.log(user_);
          //move to dashbaord with data
          localStorage.setItem("token", user_.data);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    logOut();
  }, []);
  useEffect(()=>{

    if(localStorage.getItem("token")){
      const run = async()=>{
        
        try {
          const res = await axios.post('/auth/validate_token', {
            token:localStorage.getItem("token")
          })
          console.log('res: ', res)
          setTimeout(() => {
              navigate('/dashboard', {replace:true})
            }, 1500);
            return  null
          } catch (error) {
              if(error.status == 401){
                setTimeout(()=>{
                  setIsAuth(true)
                },1500)
                // navigate("/login", {replace:true})
              }
          }
        }
        run()
      }else{
          setIsAuth(true)
      }
      
    },[])
      
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8 p-16">
      {/* {isMobile && <BackButton />} */}
      {!isAuth ?
  <Grid
    visible={true}
    height="50"
    width="50"
    color="#de2456"
    ariaLabel="grid-loading"
    radius="12.5"
    wrapperStyle={{}}
    wrapperClass="grid-wrapper"
    />:
<div className="flex min-h-full flex-1 flex-col justify-center items-center ">
      {modalToggle && (
        <ModalContainer
          modalToggle={modalToggle}
          setModalToggle={setModalToggle}
        >
          <ForgotPassword />
        </ModalContainer>
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col justify-center items-center">
        <Logo width={80} height={80} />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>
      {/* {profile ? (
                <div>
                    <img src={profile.picture} alt="user image" />
                    <h3>User Logged in</h3>
                    <p>Name: {profile.name}</p>
                    <p>Email Address: {profile.email}</p>
                    <br />
                    <br />
                    <button onClick={logOut}>Log out</button>
                </div>
            ) : (
                <button onClick={() => login()}>Sign in with Google 🚀 </button>
            )} */}
      <div className="mt-4  sm:mx-auto w-full sm:w-full sm:max-w-[350px]">
        <form onSubmit={onSubmit} className="space-y-2">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <InpuEmail
                name={"email"}
                emailRef={emailRef}
                onChange={onChangeMail}
                onBlur={onBlurMail}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                <a
                  onClick={() => setModalToggle(!modalToggle)}
                  href="#"
                  className="font-semibold text-primary-800 hover:text-primary-500"
                >
                  Forgot password?
                </a>
              </div>
            </div>
            <div className="mt-2">
              <InputPassword
                name="password"
                passwordRef={passwordRef}
                onChange={onChangePassword}
                onBlur={onBlurPassword}
              />
            </div>
          </div>

          <div className="text-sm" style={{ color: msgToUser?.color }}>
            {msgToUser?.msg}
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            >
              Sign in
            </button>
          </div>
        </form>
        <div className="w-[100%] mt-2 rounded-2 bg-gray-100 relative flex flex-row  justify-center items-center p-2 shadow text-sm font-semibold">
          <button onClick={() => login()}>Sign in with Google</button>
          <div className="absolute right-4 ">
            <FcGoogle />
          </div>
        </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?{" "}
          <a
            href="/register"
            className="font-semibold leading-6 text-primary-800 hover:text-primary-500"
          >
            Register
          </a>
        </p>
      </div>
</div>

      }

    </div>
  );
}

import React from "react";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./subComp/Icon";
import IconWithTooltip from "./subComp/IconWithTooltip";
import OptionsMenu from "./subComp/OptionsMenu";
import Invite from "./Invite";
import ModalContainer from "./subComp/ModalContainer";

import {
  editRoom,
  deleteRoom,
  copyToClipboard,
  shareContent,
} from "../tools/general";

export default function EventCard(props) {
  const { room, index, setEvents, event_id, setTypeBoard } = props;
  const navigate = useNavigate();
  console.log(props)

  const [modalToggle, setModalToggle] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const dropObj = [
    {
      dropOption: "Invite",
      actionFunc: () => setModalToggle(true),
      iconName: "invite",
    },
    {
      dropOption: "Edit Room",
      actionFunc: () =>
        editRoom(room.room_id, event_id, setEvents, setTypeBoard),
      iconName: "edit",
    },
    {
      dropOption: "Delete Room",
      actionFunc: () => deleteRoom(room.room_id, event_id, setEvents),
      iconName: "delete",
    },
  ];

  const copyAction = () => {
    copyToClipboard(
      `http://10.0.0.12:3001/events/${room.event_id}/${room.room_id}`
    );
    setLinkCopied(true);
  };

  useEffect(() => {
    const to = setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
    return () => {
      clearTimeout(to);
    };
  }, [linkCopied]);

  return (
    <div
      style={{
        backgroundImage: `url(${window.location.origin}/api/images/${room.room_id}/QR_${room.room_id}.png)`,
        // backgroundSize: "contain",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // height: "200px",
        // width: "140px",
      }}
      className="relative flex flex-col w-[170px] min-w-[170px] h-[250px] bg-white rounded-2xl"
    >
      {modalToggle && (
        <ModalContainer
          modalToggle={modalToggle}
          setModalToggle={setModalToggle}
        >
          <Invite />
        </ModalContainer>
      )}
      <div
        style={{ borderRadius: "10px 10px 0px 0px" }}
        className="absolute w-[100%] flex justify-between items-center px-2 py-1  bg-black top-0 text-white"
      >
        <span className="text-sm">{room.room_name}</span>
        <OptionsMenu dropObj={dropObj} />
        {/* <p>{room.room_max_guest}</p> */}
      </div>
      <div
        className="w-[100%] h-[100%] cursor-pointer"
        onClick={() => navigate(`/events/${event_id}/${room.room_id}`)}
      >
        {/* <img
          src={`${window.location.origin}/api/images/${room.room_id}/QR_${room.room_id}.png`}
        /> */}
      </div>
      <div
        style={{ borderRadius: "0px 0px 10px 10px" }}
        className="absolute w-[100%] flex justify-start items-center px-2 py-1   bg-black bottom-0"
      >
        <div
          className="w-[25px] shadow flex justify-center items-center h-[25px] mr-0.5"
          onClick={() => {
            const downloadFile = async (url, filename) => {
              try {
                const response = await fetch(url);
                if (!response.ok)
                  throw new Error("Network response was not ok");
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = blobUrl;
                link.setAttribute("download", filename);
                document.body.appendChild(link);
                link.click();
                link.remove();

                // Clean up the object URL
                window.URL.revokeObjectURL(blobUrl);
              } catch (error) {
                console.error("Error downloading the file:", error);
              }
            };
            downloadFile(
              `http://10.0.0.12:3001/api/images/${room.room_id}/QR_${room.room_id}.png`,
              "qr.png"
            );
          }}
        >
          <IconWithTooltip
            name="qrCode"
            size={14}
            color="white"
            tooltip={"Download QR code"}
          />
        </div>
        <div
          className="w-[25px] shadow flex justify-center items-center h-[25px] mr-0.5"
          onClick={() =>
            shareContent(
              `http://10.0.0.12:3001/events/${room.event_id}/${room.room_id}`
            )
          }
        >
          <IconWithTooltip
            name="share"
            size={14}
            color="white"
            tooltip={"Share"}
          />
        </div>
        <div
          className="w-[25px] shadow flex justify-center items-center h-[25px] mr-0.5"
          onClick={() => copyAction()}
        >
          <IconWithTooltip
            name="link"
            size={14}
            color="white"
            tooltip={linkCopied ? "Copied!" : "Copy Link"}
          />
        </div>
        <div
          className="w-[25px] shadow flex justify-center items-center h-[25px] mr-0.5"
          onClick={() => setTypeBoard("gallery")}
        >
          <IconWithTooltip
            name="galleryView"
            size={18}
            color="white"
            tooltip={"Gallery Display"}
          />
        </div>
        <div
          className="w-[25px] shadow flex justify-center items-center h-[25px] mr-0.5"
          // onClick={() => setTypeBoard("gallery")}
        >
          <IconWithTooltip
            name="people"
            size={18}
            color="white"
            tooltip={"Guest List"}
          />
        </div>
      </div>

      {/* <p style={{ fontSize: 8 }}>
        {window.location.origin}/events/{room.event_id}/{room.room_id}
      </p> */}
    </div>
  );
}

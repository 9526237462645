import React from "react";
import Icon from "./Icon";

function IconWithTooltip(props) {
  const { name, size, color, choose, onClick, tooltip } = props;

  return (
    <div className="relative flex items-center justify-center group">
      {/* Icon */}
      <Icon
        name={name}
        size={size}
        color={color}
        choose={choose}
        onClick={onClick}
      />

      {/* Tooltip */}
      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition">
        {tooltip && tooltip}
      </div>
    </div>
  );
}

export default IconWithTooltip;

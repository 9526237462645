import React, { useState } from "react";
import Icon from "./Icon";

export default function InpuEmail(props) {
  const { name, emailRef, onChange, onBlur, disabled = false } = props;
  return (
    <div className="relative">
      <input
        ref={emailRef}
        id={name}
        name={name}
        type="email"
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="email"
        required
        disabled={disabled}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
        style={{
          outline: `2px solid ${
            emailRef?.current?.isValidate
              ? "green"
              : emailRef?.current?.isValidate === false
              ? "red"
              : null
          }`,
        }}
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import DrawerMenu from "./DrawerMenu";
import Layout from "./Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { activateAccount } from "../services";
export default function Activate() {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const { event_id, room_id } = location?.state || {};
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      try {
        const res = await activateAccount(location.search);
        console.log(res.data);
        if (res.status == 200) {
          navigate("/login");
          // setMessage(res.message)
        } else {
          console.log("else in Activate");
          console.log(res);
        }
      } catch (error) {
        console.log("failed in Activate");
        console.log(error);
      }
    };
    run();
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 p-16">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          alt="Your Company"
          src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
          className="mx-auto h-10 w-auto"
        />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 underline">
          activate page
        </h2>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          {message}
        </h2>
      </div>
    </div>
  );
}

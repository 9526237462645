import React from "react";
import { useState, useEffect, useRef } from "react";
import useMobile from "./useMobile";
import Icon from "./subComp/Icon";
import { inviteMail } from "../services";
import { useNavigate } from "react-router-dom";
import InpuEmail from "./subComp/InpuEmail";
import { validateEmail } from "../tools/general";

function Invite(props) {
  const { room_id } = props;

  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  const navigate = useNavigate();
  const emailRef = useRef(null);

  // MAIL management
  const onChangeMail = (e) => {
    // console.log(
    //   "onChangeMail: ",
    //   e.target.value,
    //   validateEmail(e.target.value)
    // );
  };

  const onBlurMail = (e) => {
    console.log("onBlurEmail: ", e.target.value, validateEmail(e.target.value));
  };

  // ONSUBMIT
  const onSubmit = async (e) => {
    e.preventDefault();
    const { email } = e.target;

    console.log("ENTER send invitations mails");
    console.log(email.value);

    emailRef.current.isValidate = validateEmail(email.value);
    console.log(emailRef.current.isValidate);
    if (!emailRef.current.isValidate) {
      return setMsgToUser({
        color: "red",
        msg: "Not a valid mail.",
      });
    } else {
      setMsgToUser({
        color: "green",
        msg: "",
      });
    }

    // שליחת מייל למיילים שהוזנו
    // pass tests - try to send
    console.log("Try to send for invitations mails..");

    try {
      console.log(localStorage.getItem("token"));
      const token = localStorage.getItem("token");
      const res = await inviteMail(email.value, room_id, token);
      console.log(res);
      if (res.status == 200) {
        console.log(res.data.message);
        setMsgToUser({
          color: "green",
          msg: `we've sent you a mail to ${email.value}`,
        });
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: res.data.message });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
      setMsgToUser({ color: "red", msg: err.response.data.message });
    }
  };

  // useEffect(() => {
  //   if (!msgToUser) return;
  //   const to = setTimeout(() => {
  //     setMsgToUser(null);
  //   }, 5000);
  //   return () => clearTimeout(to);
  // }, [msgToUser]);

  return (
    <div className="flex flex-col bg-white w-[100%] justify-center items-center rounded-[5px]">
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="m-3 p-3">
          <label htmlFor="email" className="block font-medium leading-6">
            Enter mails to send invitations
          </label>
          <div>
            <InpuEmail
              name={"email"}
              emailRef={emailRef}
              onChange={onChangeMail}
              onBlur={onBlurMail}
            />
          </div>
        </div>

        {msgToUser ? (
          <div className="text-center" style={{ color: msgToUser.color }}>
            {msgToUser.msg}
          </div>
        ) : null}

        <div className="p-3">
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Send invitations
          </button>
        </div>
      </form>
    </div>
  );
}

export default Invite;

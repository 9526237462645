import React, { useState } from "react";
import useMobile from "../useMobile";
import ScrollView from "../ScrollView";
import Container, { ContainerLayout } from "../Container";

export default function TabsLayout(props) {
  const { tabs, size } = props;
  const [index, setIndex] = useState(0);
  const { isMobile } = useMobile();

  // <div
  //   className={`flex flex-col w-[100%]  gap-4 pt-2 pb-4  overflow-auto`}
  // >
  return (
      <Container dir="col">
        <ContainerLayout dir="row">
          {/* <div
          className={`w-full ${size=="sm"?"":"h-[50px]"} flex overflow-auto flex-row gap-4 ${
            !isMobile && size!=="sm" ? "px-[210px]" : ""
          }  justify-start px-4 items-center`}
        > */}
        <div className="w-full h-[50px] flex justify-start items-center">

          {tabs.map((tab, indexTab) => {
            return (
              <span
                className={`${index == indexTab ? "text-black font-bold" : "text-gray-400"
                  }   cursor-pointer px-2 whitespace-nowrap ${size == "sm" ? "sm" : 'text-lg'}`}
                onClick={() => setIndex(indexTab)}
              >
                {tab}
              </span>
            );
          })}
        </div>

          {/* </div> */}

        </ContainerLayout>
        {props.children[index]}
      </Container>
  );
}

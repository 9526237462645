import { useEffect, useRef, useState } from "react";
import { MdOutlineAdd } from "react-icons/md";
import Icon from "./subComp/Icon";
import EmojiPicker from "./EmojiPicker";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "./Layout";
import BackButton from "./BackButton";
import { addImage } from "../services";
import useMobile from "./useMobile";
import FloatMenu from "./subComp/FloatMenu";

const drawBackgroundImage = (ctx, src, img, crop, style, lastRotate) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.save();
  ctx.translate(crop.centerX, crop.centerY);
  ctx.rotate((style.rotate * Math.PI) / 180);
  ctx.drawImage(
    src,
    img.x,
    img.y,
    img.width,
    img.height,
    (-crop.width * style.scale) / 2,
    -img.height / (img.width / (crop.width * style.scale)) / 2,
    crop.width * style.scale,
    img.height / (img.width / (crop.width * style.scale))
  );
  ctx.restore();
};
const drawBrush = (ctx, style, position) => {
  ctx.strokeStyle = style.fillStyle;
  position.forEach((item, index) => {
    if (index > 0 && index < position.length - 1) {
      ctx.lineWidth = 5;
      ctx.lineJoin = style.linejoin;
      ctx.beginPath();
      ctx.moveTo(position[index - 1][0], position[index - 1][1]);
      ctx.lineTo(item[0], item[1]);
      ctx.stroke();
    }
  });
};
const drawText = (ctx, style, text, position, choose = false) => {
  ctx.save();
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.translate(position.x, position.y);
  ctx.rotate((style.rotate * Math.PI) / 180);
  ctx.translate(-position.x, -position.y);
  ctx.font = `${style.fontSize}px Arial`;
  ctx.fillStyle = style.fillStyle;
  ctx.strokeStyle = style.fillStyle;
  let textMetricsWidth = 0;
  let textMetricsheight = 0;
  const textMetrics = ctx.measureText(text);
  text.split("\n").forEach((line) => {
    const lineMetrics = ctx.measureText(line);
    const heightLine =
      lineMetrics.actualBoundingBoxAscent +
      lineMetrics.actualBoundingBoxDescent +
      1.6;
    if (lineMetrics.width > textMetricsWidth) {
      textMetricsWidth = lineMetrics.width;
    }
    textMetricsheight = heightLine;
  });

  const textWidth = textMetricsWidth;
  const textHeight = style.fontSize;
  const descent =
    textMetrics.actualBoundingBoxDescent || 0.25 * textMetricsheight;
  const lineHeight =
    Number(style.lineHeight || style.fontSize) * text.split("\n").length;
  const rectX = position.x - textWidth / 2;
  const rectY = position.y - textHeight + descent;
  function splitTextIntoLines(ctx, text, x, y, lineHeight) {
    const lines = text.split("\n");
    lines.forEach((line, index) => {
      ctx.fillText(line, x, y + index * lineHeight);
    });
  }
  splitTextIntoLines(ctx, text, position.x, position.y, style.fontSize);
  ctx.restore();
};
const drawImage = (
  ctx,
  img,
  position,
  style,
  height,
  width,
  scale,
  choose = false
) => {
  ctx.save();
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.translate(
    position.x1 + (width * scale) / 2,
    position.y1 + (height * scale) / 2
  );
  ctx.rotate((style.rotate * Math.PI) / 180);
  ctx.translate(
    -position.x1 - (width * scale) / 2,
    -position.y1 - (height * scale) / 2
  );
  ctx.drawImage(img, position.x1, position.y1, height * scale, width * scale);
  ctx.restore();
};

export default function Edit(props) {
  console.log(props);
  const { lastImage } = props;
  const location = useLocation();
  console.log(location);
  const { event_id, room_id } = location?.state || {};
  console.log(event_id, room_id);
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const canvasTouchesRef = useRef(null);
  const canvasRef = useRef(null);
  const mirrorRef = useRef(null);
  const refFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const initialDistance = useRef(null);
  const lastPosition = useRef(null);
  const textareaRef = useRef(null);
  const lastScale = useRef(100);
  const lastRotate = useRef(0);
  const lastAnagel = useRef(0);
  const firstDistance = useRef(100);
  const initialAngle = useRef(null);
  const canvasSelectRef = useRef(null);
  const canvasShapesRef = useRef(null);
  const ctxRef = useRef(null);
  const lastPoint = useRef({ x: 0, y: 0 });
  const [drag, setDrag] = useState(false);
  const ctxSelectRef = useRef(null);
  const ctxShapesRef = useRef(null);
  const ctxTouchesRef = useRef(null);
  const [image, setImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [shapes, setShapes] = useState([]);
  const lastScaleRef = useRef(100);
  const scaleRef = useRef(100);
  const [toggleEmoji, setToggleEmoji] = useState(false);
  const [activeEvent, setActiveEvent] = useState(false);

  const [select, setSelect] = useState(null);
  const [options, setOptions] = useState({
    color: "red",
    background: "green",
    widthLine: 2,
  });
  const [contentInput, setContentInput] = useState([]);
  const [toggleText, setToggleText] = useState(false);
  const [preview, setPreview] = useState(false);

  // types [ 'brush', 'text', 'emoji','sticker','gif', 'move', 'crop' ]

  const [type, setType] = useState("move");

  const categoriesEmoji = [
    { id: "people", icon: <Icon name="smile" color="gray" size={25} /> },
    { id: "nature", icon: <Icon name="dog" color="gray" size={25} /> },
    { id: "activity", icon: <Icon name="football" color="gray" size={25} /> },
    { id: "places", icon: <Icon name="building" color="gray" size={25} /> },
    { id: "objects", icon: <Icon name="lightblub" color="gray" size={25} /> },
    { id: "symbols", icon: <Icon name="emoji" color="gray" size={25} /> },
    { id: "foods", icon: <Icon name="food" color="gray" size={25} /> },
  ];

  const categoriesSticker = [
    { id: "people", icon: <Icon name="smile" color="gray" size={25} /> },
  ];

  useEffect(() => {
    if (event_id && room_id) setActiveEvent(true);
    canvasRef.current.width = window.innerWidth;
    canvasRef.current.height = window.innerHeight;
    canvasSelectRef.current.width = canvasRef.current.width;
    canvasSelectRef.current.height = canvasRef.current.height;
    canvasShapesRef.current.width = canvasRef.current.width;
    canvasShapesRef.current.height = canvasRef.current.height;
    canvasTouchesRef.current.width = canvasRef.current.width;
    canvasTouchesRef.current.height = canvasRef.current.height;

    ctxRef.current = canvasRef.current.getContext("2d");
    ctxSelectRef.current = canvasSelectRef.current.getContext("2d");
    ctxShapesRef.current = canvasShapesRef.current.getContext("2d");
    ctxTouchesRef.current = canvasTouchesRef.current.getContext("2d");
    const img = new Image();
    img.src = lastImage;
    img.onload = function () {
      // setImage(img)
      // ctxRef.current.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height, 0, 0, img.width, img.height,);
      const obj = {
        src: img,
        type: "image",
        style: { scale: 1, rotate: 0 },
        position: { x: 0, y: 0, width: img.width, height: img.height },
        cropPosition: {
          x: 0,
          y: 0,
          centerX: window.innerWidth / 2,
          centerY: window.innerHeight / 2,
          width: window.innerWidth,
          height: window.innerHeight,
        },
        width: img.width,
        height: img.height,
      };
      setImage(obj);
      // setOriginalImage(img.src);
    };
  }, []);
  useEffect(() => {
    if (file) {
      if (canvasRef.current) {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;

        ctxRef.current = canvasRef.current.getContext("2d");
        ctxTouchesRef.current = canvasTouchesRef.current.getContext("2d");
        const img = new Image();
        img.onload = function () {
          const obj = {
            src: img,
            type: "image",
            style: { scale: 1, rotate: 0 },
            position: { x: 0, y: 0, width: img.width, height: img.height },
            cropPosition: {
              x: 0,
              y: 0,
              centerX: window.innerWidth / 2,
              centerY: window.innerHeight / 2,
              width: window.innerWidth,
              height: window.innerHeight,
            },
            width: img.width,
            height: img.height,
          };
          setImage(obj);
          setOriginalImage(img.src);
        };
        img.src = URL.createObjectURL(file);
      }
    }
  }, [file]);

  function getDistance(touch1, touch2) {
    return Math.sqrt(
      Math.pow(touch2.clientX - touch1.clientX, 2) +
      Math.pow(touch2.clientY - touch1.clientY, 2)
    );
  }

  function getAngle(touch1, touch2) {
    return (
      Math.atan2(
        touch2.clientY - touch1.clientY,
        touch2.clientX - touch1.clientX
      ) *
      (180 / Math.PI)
    );
  }
  const checkPosition = (shape, clientX, clientY) => {
    const rect = canvasSelectRef.current.getBoundingClientRect();
    const adjustedX = clientX - rect.left;
    const adjustedY = clientY - rect.top;
    if (shape.type == "emoji" || shape.type == "text") {
      ctxSelectRef.current.font = `${shape.style.fontSize}px Arial`;
      let textMetricsWidth = 0;
      let textMetricsheight = 0;
      const textMetrics = ctxSelectRef.current.measureText(shape.text);
      shape.text.split("\n").forEach((line) => {
        const lineMetrics = ctxSelectRef.current.measureText(line);
        const heightLine =
          lineMetrics.actualBoundingBoxAscent +
          lineMetrics.actualBoundingBoxDescent +
          1.6;
        if (lineMetrics.width > textMetricsWidth) {
          textMetricsWidth = lineMetrics.width;
        }
        textMetricsheight = heightLine;
      });

      const textWidth = textMetricsWidth;
      const textHeight = shape.style.fontSize; // Use a fixed height or adjust based on font settings
      const descent =
        textMetrics.actualBoundingBoxDescent || 0.25 * textMetricsheight;
      const lineHeight =
        Number(shape.style.lineHeight || shape.style.fontSize) *
        shape.text.split("\n").length;

      const rectX = shape.position.x - textWidth / 2;
      const rectY = shape.position.y - textHeight + descent;

      const bound = {
        x1: rectX,
        y1: rectY,
        x2: rectX + textWidth,
        y2: rectY + Number(lineHeight),
      };
      return (
        bound.x1 < adjustedX &&
        bound.x2 > adjustedX &&
        bound.y1 < adjustedY &&
        bound.y2 > adjustedY
      );
    } else if (shape.type == "sticker") {
      return (
        shape.position.x1 < adjustedX &&
        shape.position.x1 + shape.width * shape.style.scale > adjustedX &&
        shape.position.y1 < adjustedY &&
        shape.position.y1 + shape.height * shape.style.scale > adjustedY
      );
    } else if (shape.type == "brush") {
      return (
        shape.bounding.x1 < adjustedX &&
        shape.bounding.x2 > adjustedX &&
        shape.bounding.y1 < adjustedY &&
        shape.bounding.y2 > adjustedY
      );
    }
  };

  useEffect(() => {
    let lastScale_ = 100;
    let lastRotate_ = 0;
    let currentScale = 1;
    let currentRotate = 0;
    let count = 0;
    console.log(image, type, shapes, drag, select);
    const touchStart = (e) => {
      e.preventDefault();
      const { touches } = e;

      switch (type) {
        case "move":
          if (select) {
            if (touches.length == 2) {
              initialDistance.current = getDistance(touches[0], touches[1]);
              initialAngle.current = getAngle(touches[0], touches[1]);
              currentScale = select.style?.fontSize || 1;
              currentRotate = select.style?.rotate || 0;
            } else {
              const { clientX, clientY } = touches[0];
              if (checkPosition(select, clientX, clientY)) {
                if (select.type == "sticker") {
                  lastPoint.current = {
                    x: clientX - select.position.x1,
                    y: clientY - select.position.y1,
                  };
                }
                setDrag(true);
              } else {
                for (let i = 0; i < shapes.length; i++) {
                  const shape = shapes[i];
                  if (checkPosition(shape, clientX, clientY)) {
                    // console.log('-here')
                    setShapes((prev) => prev.filter((_, j) => j !== i));
                    setShapes((prev) => [...prev, select]);
                    setSelect(shape);
                    setDrag(true);
                    break;
                  }
                }
              }
            }

            // Handle selected case logic here if needed
          } else {
            for (let i = 0; i < shapes.length; i++) {
              const shape = shapes[i];
              if (
                checkPosition(shape, touches[0].clientX, touches[0].clientY)
              ) {
                setShapes((prev) => prev.filter((_, j) => j !== i));
                if (shape.type === "sticker") {
                  lastPoint.current = {
                    x: touches[0].clientX - shape.position.x1,
                    y: touches[0].clientY - shape.position.y1,
                  };
                }
                setSelect(shape);
                setDrag(true);
                break;
              }
            }
          }
          break;

        case "crop":
          if (touches.length === 2) {
            const finger_1 = touches[0];
            const finger_2 = touches[1];
            firstDistance.current = getDistance(finger_1, finger_2);
            initialAngle.current =
              getAngle(finger_1, finger_2) - lastRotate.current;
          } else {
            const finger_1 = touches[0];
            lastPosition.current = finger_1;
          }
          break;

        case "brush":
          // Destructure clientX and clientY only within the "brush" case
          const { clientX, clientY } = touches[0];
          const lineWidth = 40;
          const position = { x: clientX, y: clientY };
          const obj = {
            type: "brush",
            style: {
              fillStyle: options.color,
              lineWidth,
              lineJoin: "round",
              rotate: 0,
            },
            position,
            bounding: { x1: clientX, x2: clientX, y1: clientY, y2: clientY },
            points: [[clientX, clientY]],
          };
          setShapes((prev) => {
            prev.push(obj);
            return [...prev];
          });
          break;

        case "stickers":
          // Add your sticker logic here if needed
          break;

        case "text":
          // Add your text logic here if needed
          break;

        default:
          break;
      }
    };

    const touchMove = (e) => {
      e.preventDefault();
      const { touches } = e;

      console.log(type);
      switch (type) {
        case "move":
          console.log(select, drag);
          if (select && drag) {
            if (touches.length == 2) {
              if (select.type == "emoji" || select.type == "text") {
                const centerX =
                  touches[0].clientX +
                  (touches[1].clientX - touches[0].clientX) / 2;
                const centerY =
                  touches[0].clientY +
                  (touches[1].clientY - touches[0].clientY) / 2;
                const newDistance = getDistance(touches[0], touches[1]);
                const newAngle = getAngle(touches[0], touches[1]);
                const scale =
                  newDistance - initialDistance.current + lastScaleRef.current;
                const rotate_ = newAngle - initialAngle.current;
                scaleRef.current = scale;
                setSelect((prev) => ({
                  ...prev,
                  style: {
                    ...prev.style,
                    rotate: rotate_,
                    scale: scale,
                    fontSize: (40 * scale) / 100,
                  },
                }));
              }
            } else {
              setSelect((prev) => ({
                ...prev,
                position:
                  prev.type === "emoji" || prev.type === "text"
                    ? { x: touches[0].clientX, y: touches[0].clientY }
                    : prev.type === "sticker" && {
                      x1: touches[0].clientX - lastPoint.current.x,
                      y1: touches[0].clientY - lastPoint.current.y,
                      x2:
                        touches[0].clientX +
                        prev.width * prev.style.scale -
                        lastPoint.current.x,
                      y2:
                        touches[0].clientY +
                        prev.height * prev.style.scale -
                        lastPoint.current.y,
                    },
              }));
            }
          }
          break;

        case "crop":
          if (touches.length === 2) {
            const finger_1 = touches[0];
            const finger_2 = touches[1];
            const newAngle = getAngle(finger_1, finger_2);
            const rotate_ = newAngle - initialAngle.current;
            lastAnagel.current = rotate_;
            initialDistance.current =
              getDistance(finger_1, finger_2) - firstDistance.current;

            const scale = (initialDistance.current + lastScale.current) / 100;
            console.log(initialDistance.current, lastScale.current);
            if (scale > 0.4) {
              const centerX =
                touches[0].clientX +
                (touches[1].clientX - touches[0].clientX) / 2;
              const centerY =
                touches[0].clientY +
                (touches[1].clientY - touches[0].clientY) / 2;

              const newWidth = image.cropPosition.width * scale;
              const newHeight = image.cropPosition.height * scale;
              const deltaX = newWidth - image.cropPosition.width;
              const deltaY = newHeight - image.cropPosition.height;
              const newX = image.cropPosition.x - deltaX / 2;
              const newY = image.cropPosition.y - deltaY / 2;
              console.log("Sasddasd");
              setImage((prev) => ({
                ...prev,
                style: {
                  ...prev.style,
                  scale: scale,
                  rotate: rotate_,
                },
                cropPosition: {
                  ...prev.cropPosition,
                  centerX,
                  centerY,
                  x: newX,
                  y: newY,
                },
              }));
            }
          } else {
            const { clientX, clientY } = touches[0];
            // Additional single-touch crop logic if needed
          }
          break;

        case "brush":
          const { clientX, clientY } = touches[0];
          setShapes((prev) => {
            const lastShape = prev[prev.length - 1];
            if (clientX < lastShape.bounding.x1)
              lastShape.bounding.x1 = clientX;
            if (clientX > lastShape.bounding.x2)
              lastShape.bounding.x2 = clientX;
            if (clientY < lastShape.bounding.y1)
              lastShape.bounding.y1 = clientY;
            if (clientY > lastShape.bounding.y2)
              lastShape.bounding.y2 = clientY;
            lastShape.points.push([clientX, clientY]);
            return [...prev];
          });
          break;

        case "stickers":
          // Add your stickers logic here if needed
          break;

        case "text" || "emoji":
          break;

        default:
          break;
      }
    };

    const touchEnd = (e) => {
      e.preventDefault();
      switch (type) {
        case "move":
          setDrag(false);
          break;
        case "crop":
          if (e.touches.length == 0) {
            lastRotate.current = lastAnagel.current;
            lastScale.current += initialDistance.current;
          }
          break;
        case "brush":
          break;
        case "stickers":
          break;
        case "text":
          break;
      }
    };

    if (canvasTouchesRef.current) {
      canvasTouchesRef.current.style.touchAction = "none"; // Prevent default gestures
      canvasTouchesRef.current.addEventListener("touchstart", touchStart);
      canvasTouchesRef.current.addEventListener("touchmove", touchMove);
      canvasTouchesRef.current.addEventListener("touchend", touchEnd);
    }

    return () => {
      if (canvasTouchesRef.current) {
        canvasTouchesRef.current.removeEventListener("touchmove", touchMove);
        canvasTouchesRef.current.removeEventListener("touchstart", touchStart);
        canvasTouchesRef.current.removeEventListener("touchend", touchEnd);
      }
    };
  }, [image, type, shapes, drag, select]);

  useEffect(() => {
    if (image && ctxRef.current) {
      ctxRef.current.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );
      drawBackgroundImage(
        ctxRef.current,
        image.src,
        image.position,
        image.cropPosition,
        image.style,
        lastRotate.current
      );
    }
  }, [image]);

  useEffect(() => {
    if (ctxShapesRef.current) {
      ctxShapesRef.current.clearRect(
        0,
        0,
        canvasShapesRef.current.width,
        canvasShapesRef.current.height
      );
      shapes.forEach((shape) => {
        if (shape.type == "text" || shape.type == "emoji") {
          drawText(
            ctxShapesRef.current,
            shape.style,
            shape.text,
            shape.position
          );
        } else if (shape.type == "sticker") {
          drawImage(
            ctxShapesRef.current,
            shape.src,
            shape.position,
            shape.style,
            shape.height,
            shape.width,
            shape.style.scale
          );
        } else {
          drawBrush(ctxShapesRef.current, shape.style, shape.points);
        }
      });
    }
  }, [shapes]);

  useEffect(() => {
    if (ctxSelectRef.current && select) {
      ctxSelectRef.current.clearRect(
        0,
        0,
        canvasSelectRef.current.width,
        canvasSelectRef.current.height
      );
      if (select.type == "text" || select.type == "emoji") {
        drawText(
          ctxSelectRef.current,
          { ...select.style, fontSize: select.style.fontSize },
          select.text,
          select.position,
          true
        );
      } else if (select.type == "sticker") {
        drawImage(
          ctxSelectRef.current,
          select.src,
          select.position,
          select.style,
          select.height,
          select.width,
          select.style.scale
        );
      } else if (select.type == "brush") {
        drawBrush(ctxShapesRef.current, select.style, select.points);
      }
    }
  }, [select]);
  const clearAll = () => {
    ctxSelectRef.current.clearRect(
      0,
      0,
      canvasSelectRef.current.width,
      canvasSelectRef.current.height
    );
    ctxShapesRef.current.clearRect(
      0,
      0,
      canvasShapesRef.current.width,
      canvasShapesRef.current.height
    );
  };
  function saveForPreview() {
    const newCanvas = document.createElement("canvas");
    const newCtx = newCanvas.getContext("2d");

    setPreview(true);

    // newCanvas.width = canvasRef.current.width
    // newCanvas.height = canvasRef.current.height
    // newCtx.drawImage(canvasRef.current, 0,0)
    // newCtx.drawImage(canvasShapesRef.current, 0,0)
    // newCtx.drawImage(canvasSelectRef.current, 0,0)
    // console.log(newCanvas.toDataURL("image/jpeg", 0.5))
  }

  const onSubmit = (e) => {
    e.preventDefault();
    onCreateText(e.target.text.value);
    setToggleText(false);
    document.activeElement.blur();
  };

  const onCreateText = (value) => {
    const fontSize = 40;
    ctxSelectRef.current.font = `${fontSize}px Arial`;
    const textMetrics = ctxSelectRef.current.measureText(value);
    const textWidth = textMetrics.width;
    const position = {
      x: canvasSelectRef.current.width / 2 - textWidth / 2,
      y: canvasSelectRef.current.height / 2,
    };
    const obj = {
      type: "text",
      style: { fillStyle: "red", fontSize, rotate: 0 },
      text: value,
      position,
    };
    setShapes((prev) => {
      prev.push(obj);
      return [...prev];
    });
    setType("move");
  };
  const updateMirror = (e) => {
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    const content = e.target.value
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/\n/g, "<br>");
    const split = content.split("<br>");
    setContentInput(split);
  };
  const addEmojiShape = (value) => {
    const fontSize = 40;
    const position = { x: 100, y: 100 };
    const obj = {
      type: "emoji",
      style: { fillStyle: "red", fontSize, rotate: 0 },
      text: value,
      position,
    };
    setShapes((prev) => {
      prev.push(obj);
      return [...prev];
    });
    setType("move");
  };
  const saveInPhone = async () => {
    const newCanvas = document.createElement("canvas");
    const newCtx = newCanvas.getContext("2d");
    newCanvas.width = canvasRef.current.width;
    newCanvas.height = canvasRef.current.height;
    newCtx.drawImage(canvasRef.current, 0, 0);
    newCtx.drawImage(canvasShapesRef.current, 0, 0);
    newCtx.drawImage(canvasSelectRef.current, 0, 0);
    // console.log(newCanvas.toDataURL("image/jpeg", 0.5))
    // Convert canvas to blob
    newCanvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);

        // Check if the Web Share API is available
        if (navigator.canShare && navigator.canShare({ files: [blob] })) {
          const file = new File([blob], "image.png", { type: "image/png" });
          navigator
            .share({
              files: [file],
              title: "My Image",
              text: "Check out this image!",
            })
            .catch((error) => console.error("Error sharing:", error));
        } else {
          // Fallback: Create a download link
          const a = document.createElement("a");
          a.href = url;
          a.download = "my_image.png";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
        URL.revokeObjectURL(url);
      }
    }, "image/png");
  };

  const shareImageToWhatsApp = async () => {
    const newCanvas = document.createElement("canvas");
    const newCtx = newCanvas.getContext("2d");
    newCanvas.width = canvasRef.current.width;
    newCanvas.height = canvasRef.current.height;
    newCtx.drawImage(canvasRef.current, 0, 0);
    newCtx.drawImage(canvasShapesRef.current, 0, 0);
    newCtx.drawImage(canvasSelectRef.current, 0, 0);
    // Convert canvas to a blob
    newCanvas.toBlob(async (blob) => {
      if (blob) {
        const file = new File([blob], "image.png", { type: "image/png" });

        // Check if the Web Share API can handle files
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          try {
            await navigator.share({
              files: [file],
              title: "Share this image",
              text: "Check out this image!",
            });
            console.log("Image shared successfully!");
          } catch (error) {
            console.error("Error sharing:", error);
          }
        } else {
          console.warn("Web Share API not supported for sharing files.");
          // Fallback option (optional): prompt user to download and share manually
        }
      }
    }, "image/png");
  };

  const publishImage = async () => {
    const newCanvas = document.createElement("canvas");
    const newCtx = newCanvas.getContext("2d");
    newCanvas.width = canvasRef.current.width;
    newCanvas.height = canvasRef.current.height;
    newCtx.drawImage(canvasRef.current, 0, 0);
    newCtx.drawImage(canvasShapesRef.current, 0, 0);
    newCtx.drawImage(canvasSelectRef.current, 0, 0);
    console.log(newCanvas.toDataURL("image/jpeg", 0.7));

    try {
      const res = await addImage(
        room_id,
        event_id,
        localStorage.getItem("token"),
        newCanvas.toDataURL("image/jpeg", 0.7)
      );
      if (res.status == 200) {
        navigate(`/events/${event_id}/${room_id}/feed`);
      } else {
        console.log("else in addImage in Edit.js");
        console.log(res);
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
    }
  };

  const menuFloatData = [{
    icon: { name: "crop", size: 20 },
    toggleWhenClick: true,

    // onClick:()=>navigate("/edit"),
    style: "bg-white"
  },
  {
    toggleWhenClick: true,
    icon: { name: "emoji", size: 20 },
    // onClick:()=>navigate("/camera"),
    style: "bg-white"
  },
  {
    icon: { name: "text", size: 20 },
    toggleWhenClick: true,

    // onClick:()=>navigate("/scanner"),
    style: "bg-white"
  },
  {
    icon: { name: "brush", size: 20 },
    toggleWhenClick: true,

    // onClick:()=>navigate("/scanner"),
    style: "bg-white"
  },
  {
    icon: { name: "plus", size: 20 },
    toggleWhenClick: true,
    // onClick:()=>setTypeBoard("build-events"),
    style: "bg-primary-600 text-white"
  }]

  return (
    <div className="relative isolate bg-red-900  lg:px-8 flex flex-col justify-center items-center h-[100%]">
      <div className="text-white absolute top-0 left-0 z-10 flex flex-row gap-4 justify-between px-8 py-4 items-center w-full bg-white"
        style={{ borderRadius: '0px 0px 0px 0px' }}
      >
        <div><Icon name="back" color={"black"} /></div>
        <div className="flex flex-row gap-4">
          <div className="text-black flex flex-row gap-2 items-center"><Icon name="download" color={"black"} /> Download</div>
          <div className="text-black flex flex-row gap-2 items-center"><Icon name="eye-open" color={"black"} /> Preview</div>
        </div>
      </div>
      <div className="absolute flex justify-center items-center text-white right-8 bottom-[40px] bg-primary-800 rounded-[15px]  h-[40px] px-4 z-20"
         onClick={() => publishImage()}
     >
        <span className="mx-2">
          publish
        </span>

        <Icon name="next" color="white" size={25} />

      </div>
      <FloatMenu buttons={menuFloatData} />
      <div
        style={{ touchAction: "none" }}
        className="relative top-0 left-0 w-[100%] h-[100%] bg-white"
      >

        {/* <Layout event_id={event_id} room_id={room_id} /> */}

        <canvas
          className="absolute top-0 left-0 w-[100%] h-[100%]"
          style={{ zIndex: 4, touchAction: "none" }}
          ref={canvasTouchesRef}
          id="touches-canvas"
        />

        <canvas ref={canvasRef} id="original-image" />
        <canvas
          className="absolute top-0 left-0"
          style={{ zIndex: 2 }}
          ref={canvasShapesRef}
          id="shapes-canvas"
        ></canvas>

        <canvas
          className="absolute top-0 left-0"
          style={{ zIndex: select ? 3 : 2 }}
          ref={canvasSelectRef}
          id="select-canvas"
        ></canvas>
      </div>
    </div>

  );
}

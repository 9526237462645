import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import { BrowserRouter as Router, Route, Routes, Link, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Home from "./components/Home";
import Register from "./components/Register";
import ResetPassword from "./components/ResetPassword";
import Edit from "./components/Edit";
import EntryFeed from "./components/EntryFeed";
import "./App.css";
import Room from "./components/Room";
import Camera from "./components/Camera";
import Feed from "./components/Feed";
import EmojiPicker from "./components/EmojiPicker";
import CameraScanner from "./components/CameraScanner";
import Activate from "./components/Activate";
import BuildEvents from "./components/BuildEvents";
import ProfilePage from "./components/Profile";
import AppSettings from "./components/AppSettings";
import NavBar from "./components/NavBar";
import LayoutDashboard from "./components/LayoutDashboard";
import ProtectedRoute from "./components/ProtectRoute";
import axios from "axios";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { motion } from "motion/react"
import { animate } from "motion"
const animations = {
  initial:{opacity:0, x:0}
,  animate:{opacity:1, x:0}
  ,exit:{opacity:0, x:0}
}
const AnimatedPage =({children,time})=>{
  return (
    <motion.div
    variants={animations}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration:time?time:0.5}}
    >
{children}
    </motion.div>
  )
}
// import EmoconstjiPicker from './components/EmojiPicker';
function AnimationRoute() {
  const location = useLocation();
  const [lastImage, setLastImage] = useState(null);

  return (
    <TransitionGroup className="relative w-full h-full ">
      <CSSTransition key={location.pathname} classNames="fade" timeout={0}>
        <Routes location={location}>

          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/scanner" element={<CameraScanner />} />
          <Route path="/edit" element={
              <AnimatedPage>
            <Edit lastImage={lastImage} />
              </AnimatedPage>
            
            } />


          <Route
            path="/camera"
            element={
              <AnimatedPage>
              <Camera lastImage={lastImage} setLastImage={setLastImage} />
              </AnimatedPage>
            }
          />

          <Route path="/register" element={<Register />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <AnimatedPage>
              <Dashboard />
              </AnimatedPage>
             </ProtectedRoute>
          } />
          <Route path="/build-events" element={
            <ProtectedRoute>
              <AnimatedPage>
              <BuildEvents />
              </AnimatedPage>
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <AnimatedPage>
              <ProfilePage />
              </AnimatedPage>
            </ProtectedRoute>
          } />
          <Route path="/app-settings" element={
            <ProtectedRoute>
              <AppSettings />
            </ProtectedRoute>
          } />
          <Route path="/events/:event_id/:room_id/feed" 
          element={
            <AnimatedPage
                time={3}
            >
            <Feed />
            </AnimatedPage>
            
            } />
          {/* <Route path="/events/:event_id/:room_id/edit" element={<Edit lastImage={lastImage}/>} />
      <Route path="/events/:event_id/:room_id/camera" element={<Camera lastImage={lastImage} setLastImage={setLastImage}  />} /> */}
          <Route path="/events/:event_id/:room_id" element={
              <AnimatedPage time={3}>
            
            <EntryFeed />
            </AnimatedPage>
          } />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}
function App() {
  return (
    <Router>
      {/* <EmojiPicker/> */}
      <div className="theme-light bg-white flex bg-gray-900 flex-col w-[100%] h-[100vh]">
        <AnimationRoute />
      </div>
    </Router>
  );
}

export default App;

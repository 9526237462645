import { useEffect, useState } from "react"
import useMobile from "../useMobile"
import Icon from "./Icon"

export default function FloatMenu(props) {
    const { buttons } = props
    const { isMobile } = useMobile()
    // name = "add"
    // style = "",
    // icon= "",
    // onClick = ""
    const [arrButtuns, setArrButtons] = useState(buttons)
    const [chooseElement, setChooseElement] = useState(null)


    useEffect(() => {
        if (chooseElement) {
            setArrButtons([{...chooseElement, style:"bg-primary-600 text-white"}])
        }else{
            setArrButtons(buttons)

        }
    }, [chooseElement,buttons])

    return (
        <div className={`z-10 absolute flex flex-col items-center gap-4 ${!isMobile ? 'bottom-[80px]' : 'bottom-[80px]'} left-4`}>
            {arrButtuns.map((button, index) => {
                return (
                    <div
                        className={`w-[48px] shadow-lg flex justify-center  items-center rounded-[45px] ${button.style} h-[45px]`}
                        onClick={() => {
                            console.log(button.toggleWhenClick,chooseElement )
                            if(button.toggleWhenClick && chooseElement == null){
                                setChooseElement(button)
                            }else{
                                setChooseElement(null)
                            }
                            if(button.onClick){
                                button.onClick()} }
                            }
                    >
                        <Icon name={button.icon.name} size={button.icon.size} />
                    </div>
                )
            })}


        </div>
    )
}
import { useState, useEffect } from "react";
import Icon from "./Icon";

export default function InputPassword(props) {
  const { name, passwordRef, onChange, onBlur, disabled = false } = props;
  const [toggleInput, setToggleInput] = useState(false);
  return (
    <div className="relative">
      <input
        ref={passwordRef}
        id={name}
        name={name}
        type={toggleInput ? "text" : "password"}
        onChange={onChange}
        onBlur={onBlur}
        autocomplete="new-password"
        // autoComplete="current-repassword"
        required
        disabled={disabled}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
        style={{
          outline: `2px solid ${
            passwordRef?.current?.isValidate
              ? "green"
              : passwordRef?.current?.isValidate === false
              ? "red"
              : null
          }`,
        }}
      />
      {toggleInput ? (
        <button
          type="button"
          onClick={() => {
            setToggleInput(false);
          }}
          class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-500 dark:text-neutral-500 dark:focus:text-blue-500"
        >
          <Icon name="eye-open" size={15} color={"black"} />
        </button>
      ) : (
        <button
          onClick={() => {
            setToggleInput(true);
          }}
          type="button"
          class="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-500 dark:text-neutral-500 dark:focus:text-blue-500"
        >
          <Icon name="eye-close" size={15} color={"black"} />
        </button>
      )}
    </div>
  );
}

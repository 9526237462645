export default function ScrollView(props){
    const {flexDirection} = props
    // gap-2 pt-2 pb-4 
    return (

        <div
        className={`flex ${flexDirection == "col" ? `flex-col  pb-[100px]` :'flex-row'} h-full  w-[100%]  overflow-auto`}
      >
        {props.children}
        </div>
    )
}
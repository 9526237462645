import { useEffect, useRef } from "react"
import InputDate from "./subComp/InputDate"
import InputPassword from "./subComp/InputPassword"
import InputText from "./subComp/InputText"
import useMobile from "./useMobile"

export default function CardRoom(props) {
    const {room}= props
    console.log(room)
    const {isMobile} = useMobile()
    const roomLocationRef = useRef()
    const startDateRef = useRef()
    const endDateRef = useRef()
    const passwordRef = useRef()
    useEffect(()=>{
        if(roomLocationRef.current && startDateRef.current&& endDateRef.current&&passwordRef.current )
        roomLocationRef.current.value = room.location ?? ""
        startDateRef.current.value = room.startTime
        endDateRef.current.value = room.endTime
        passwordRef.current.value = room.password
    },[room])

    return (
        <div className={`w-full p-4 gap-8   flex ${isMobile ? 'flex-col' : 'flex-row'} items-center`}
        >

            <div className=" flex flex-col   px-1">

                <div className="flex justify-start items-center w-full p-2">
                    <div className="rounded-[15px] bg-gray-300 p-2 border-gray-200 w-[150px] h-[150px]">

                        <div className="w-full h-full border-dashed	border-2 rounded-[13px] flex justify-center items-center border-gray-200">
                            <h4 className="text-sm font-semibold px-1 leading-2 text-center text-white ">Drag or Upload
                                Room Cover Image</h4>
                        </div>
                    </div>
                    {/* <div className="bg-[url(https://via.placeholder.com/150)] rounded-[15px] w-[150px] h-[150px] "></div> */}
                </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
                <div className="w-full flex flex-col gap-2 px-1">
                    <h5>Date:</h5>
                    <div className="flex flex-row ">
                        <div className="w-full px-1">
                            <InputDate name="start_date" textRef={startDateRef} />
                        </div>
                        <div className="w-full px-1" >
                            <InputDate name="end_date" textRef={endDateRef}/>
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-2  px-1">
                    <h5>Security:</h5>
                    <div>
                        <InputPassword name="password" passwordRef={passwordRef}/>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-2  px-1">

                    <InputText name="event_location"
                        textRef={roomLocationRef}
                        placeholder="Event Location" />
                </div>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react";
import Events from "./Events";
import BuildEvents from "./BuildEvents";
import FooterDashboard from "./FooterDashbaord";
import Icon from "./subComp/Icon";
import { useNavigate } from "react-router-dom";
import useMobile from "./useMobile";
import ProfilePage from "./Profile";
import { timeout } from "workbox-core/_private";
import GalleryPage from "./GalleryPage";
import EditRoom from "./EditRoom";
import AppSettings from "./AppSettings";
import FloatMenu from "./subComp/FloatMenu";
import NavBar from "./NavBar";
export default function Dashboard() {
  const navigate = useNavigate();
  const [typeBoard, setTypeBoard] = useState("events");
  const [zIndexMenu, setZindexMenu] = useState('z-[-1]')
  const [toggleMenu, setToggleMenu] = useState(false);
  const [events, setEvents] = useState([]);
  const [editEvent, setEditEvent] = useState(null);
  
  const menuFloatData = [ {
    icon:{name:"edit", size:20},
    onClick:()=>navigate("/edit"),
    style:"bg-white"
   },
   {
    icon:{name:"camera", size:20},
    onClick:()=>navigate("/camera"),
    style:"bg-white"
   },
   {
    icon:{name:"scaner", size:20},
    onClick:()=>navigate("/scanner"),
    style:"bg-white"
   },
   {
    icon:{name:"plus", size:20},
    onClick:()=>navigate("/build-events"),
    // onClick:()=>setTypeBoard("build-events"),
    style:"bg-primary-600 text-white"
   }]

  const { isMobile } = useMobile();
  // useEffect(() => {
  //   let timeout;
  //   if (toggleMenu) {
  //     setZindexMenu('z-30')
  //   } else {

  //     timeout = setTimeout(() => {
  //       if (!toggleMenu) {
  //         setZindexMenu('z-[-1]')
  //       }
  //     }, 300);
  //   }
  //   return () => clearTimeout(timeout)
  // }, [toggleMenu])
  return (
    <div className="flex flex-col w-[100%] h-[100vh]">
     <NavBar/>
      {/* <nav
        className="flex items-center justify-between px-4 py-2 lg:px-4"
        aria-label="Global"
      >

        <div

          className="peer flex flex-row justify-center items-center gap-2">

          <Icon name="menu-hamburger" color="black" size={20}

            onClick={() => {
              console.log('asdasdasd')
              setToggleMenu(prev => !prev)
            }}
          />
          <div className="flex flex-row">
         
            <div
              onClick={() => {
                // guset go to / 
                // if not guest go to /dashbord
                navigate('/')
              }}
              className="rounded-[50px] w-[30px] flex justify-center items-center  h-[30px] bg-black text-white font-sm">
              <span>
                EF
              </span>
            </div>

          </div>
        </div>

        <div className=" lg:flex lg:gap-x-12 pl-12">
          <button
            type="button"
            class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
            onClick={() => setTypeBoard('profile')}
          >
            <span class="absolute -inset-1.5"></span>
            <span class="sr-only">Open user menu</span>
            <img
              class="h-8 w-8 rounded-full"
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
          </button>
        </div>
      </nav> */}
      <div className="flex flex-row-reverse w-[100%] flex-1">
      {typeBoard !== "build-events" && (
      
        <FloatMenu buttons={menuFloatData}/>
      //  <div className={`z-10 absolute flex flex-col items-center gap-2 ${!isMobile ? 'bottom-[80px]' : 'bottom-4'} left-8`}>
      //     <div
      //       className="w-[45px] shadow-lg flex justify-center items-center rounded-[45px] bg-white h-[45px]"
      //       onClick={() => navigate("/edit")}
      //     >
      //       <Icon name="edit" size={20} />
      //     </div>
      //     <div
      //       className="w-[45px] shadow-lg flex justify-center items-center rounded-[45px] bg-white h-[45px]"
      //       onClick={() => navigate("/camera")}
      //     >
      //       <Icon name="camera" size={20} />
      //     </div>
      //     <div
      //       className="w-[45px] shadow-lg flex justify-center items-center rounded-[45px] bg-white h-[45px]"
      //       onClick={() => navigate("/scanner")}
      //     >
      //       <Icon name="scaner" size={20} />
      //     </div>
      //     <div
      //       className="w-[45px] shadow-lg flex justify-center items-center text-white rounded-[45px] bg-primary-500 h-[45px]"
      //       onClick={() => setTypeBoard("build-events")}
      //     >
      //       <Icon name="plus" size={20} />
      //     </div>
      //   </div>
      )}
        <div className="midi flex flex-col w-[100%] flex-1">

          {typeBoard == "events" && (
            <Events
              
              setEditEvent={setEditEvent}
              events={events}
              setEvents={setEvents}
              setType={setTypeBoard}
              setTypeBoard={setTypeBoard}
            />
          )}
          {/* {typeBoard == "build-events" && (
            < 
            editEvent={editEvent == null ? {}:editEvent}
              setEvents={setEvents}
              setTypeBoard={setTypeBoard}
            />
          )} */}
          {/* {typeBoard == "profile" && (
            <ProfilePage />
          )} */}
          {typeBoard == "gallery" && (
            <GalleryPage />
          )}
          {typeBoard == "edit-room" && (
            <EditRoom />
          )}
            {/* {typeBoard == "app-settings" && (
            <AppSettings />
          )} */}
        </div>
{/*         
        <div className={`w-full h-full  transform  transition-transform  absolute top-0 left-0 ${zIndexMenu}`}>

          <div
            style={{
              boxShadow: '2px 0px 8px 3px rgba(0,0,0,0.09)',
              zIndex: 2
            }}
            className={`righti flex absolute top-0  -left-96 h-full flex-col ${isMobile? 'w-8/12':'w-2/12'} bg-white transition-all  ${toggleMenu ? 'left-0 ease-in' : ' -left-96 ease-out'}   delay-20 duration-300 `}>

            <div className="flex flex-row-reverse mb-[15px] py-1 justify-between w-full items-center px-4">
              <div className="rounded-[50px] w-[30px] flex justify-center items-center  h-[30px] bg-black text-white font-sm">
                <span>
                  EF
                </span>
              </div>
              <h5 className="p-2 text-center">Menu</h5>

              <Icon name="menu-hamburger" color="black" size={20} onClick={() => setToggleMenu(prev => !prev)} />
            </div>

            <div
              onClick={() => setTypeBoard("events")}
              className={`w-[100%] h-[40px] ${typeBoard !== "events" ? "hover:bg-primary-100" : ""} cursor-pointer  ${typeBoard == "events" ? "text-white" : "text-gray-900"
                }  ${typeBoard == "events" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center px-4 text-sm font-semibold`}
            >
              <span>Home</span>
              <span><Icon name="home" color={typeBoard == "events" ? "white" : "black"} /></span>
            </div>
            <div
              onClick={() => setTypeBoard("profile")}
              className={`w-[100%] h-[40px]  ${typeBoard !== "profile" ? "hover:bg-primary-100" : ""} cursor-pointer ${typeBoard == "profile" ? "text-white" : "text-gray-900"
                }  ${typeBoard == "profile" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center px-4 text-sm font-semibold`}
            >
              <span>Profile</span>
              <span><Icon name="profile" color={typeBoard == "profile" ? "white" : "black"} /></span>
            </div>
            <div
              onClick={() => setTypeBoard("build-events")}
              className={`w-[100%]  ${typeBoard !== "build-events" ? "hover:bg-primary-100" : ""} h-[40px] cursor-pointer  ${typeBoard == "build-events" ? "text-white" : "text-gray-900"
                }  ${typeBoard == "build-events" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center px-4 text-sm font-semibold`}
            >
              <span>Create Event</span>
              <span><Icon name="plus" color={typeBoard == "build-events" ? "white" : "black"} /></span>
            </div>
            <div
              onClick={() => setTypeBoard("app-settings")}
              className={`w-[100%]  ${typeBoard !== "app-settings" ? "hover:bg-primary-100" : ""} h-[40px] cursor-pointer  ${typeBoard == "app-settings" ? "text-white" : "text-gray-900"
                }  ${typeBoard == "app-settings" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center px-4 text-sm font-semibold`}
            >
              <span>Settings</span>
              <span><Icon name="settings" color={typeBoard == "app-settings" ? "white" : "black"} /></span>
            </div>

          </div>

          <div
            style={{
              background: "rgba(0,0,0,0.5)",
              transition: "opacity 0.3s ease, z-index 0.3s ease",
              opacity: toggleMenu ? 1 : 0,
              pointerEvents: toggleMenu ? "auto" : "none"
            }}
            onClick={() => setToggleMenu(false)}
            className={`w-full ${!toggleMenu ? "opacity-0 z-[-1]" : ""} h-full absolute top-0 left-0`}></div>
        </div> */}


      </div>
    </div>
  );
}

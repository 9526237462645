import axios from "axios";
export const loginUser = async (email, password) => {
  const res = await axios.post("/auth/login", { email, password });
  return res;
};

export const registerUser = async (name, email, password) => {
  const res = await axios.post("/auth/register", { name, email, password });
  return res;
};
export const forgotPassword = async (email) => {
  const res = await axios.post("/auth/forgot_password", { email });
  return res;
};
export const resetPassword = async (password, query) => {
  const res = await axios.post("/auth/reset_password", { password, query });
  return res;
};

export const getEventData = async (token) => {
  const res = await axios.post(
    "/api/events/get_data",
    {},
    {
      headers: { token },
    }
  );
  return res;
};
export const getAllEventData = async (event_id, token) => {
  const res = await axios.post(
    "/api/events/get_all_data",
    { event_id },
    {
      headers: { token },
    }
  );
  return res;
};
export const addNewEvent = async (
  rooms,
  event_name,
  event_location,
  event_type,
  event_admins,
  token
) => {
  const res = await axios.post(
    "/api/events/add_event",
    {
      rooms,
      event_name,
      event_location,
      event_type,
      event_admins,
    },
    {
      headers: { token },
    }
  );
  return res;
};
export const getFeedData = async (roomId, token) => {
  const res = await axios.post(
    "/api/rooms/get_posts",
    {
      roomId,
    },
    {
      headers: { token },
    }
  );
  return res;
};
export const addImage = async (roomId, eventId, token, image) => {
  const res = await axios.post(
    "/api/rooms/add_image",
    {
      roomId,
      eventId,
      image,
    },
    {
      headers: { token },
    }
  );
  return res;
};
export const getInsideRoom = async (name, roomId, token) => {
  const res = await axios.post(
    "/api/rooms/get_inside",
    {
      roomId,
      name,
    },
    {
      headers: { token },
    }
  );
  return res;
};

export const connectGoogle = async (googleUser) => {
  const res = await axios.post(
    "/auth/google_auth",
    { googleUser },
    {
      headers: {
        "Content-Type": "application/json",
        // Include any other necessary headers
      },
    }
  );
  return res.data;
};

export const activateAccount = async (query) => {
  const res = await axios.post(
    "/auth/activate_account",
    { query },
    {
      headers: {
        "Content-Type": "application/json",
        // Include any other necessary headers
      },
    }
  );
  console.log(res);
  return res;
};

export const deleteEventService = async (event_id, token) => {
  const res = await axios.post(
    "/api/events/delete_event",
    { event_id },
    {
      headers: {
        "Content-Type": "application/json",
        // Include any other necessary headers
        token,
      },
    }
  );
  console.log(res);
  return res;
};

export const deleteRoomService = async (room_id, token) => {
  const res = await axios.post(
    "/api/events/delete_room",
    { room_id },
    {
      headers: {
        "Content-Type": "application/json",
        // Include any other necessary headers
        token,
      },
    }
  );
  console.log(res);
  return res;
};

export const inviteMail = async (email, room_id, token) => {
  const res = await axios.post(
    "/api/events/invite",
    { email, room_id },
    {
      headers: {
        "Content-Type": "application/json",
        // Include any other necessary headers
        token,
      },
    }
  );
  console.log(res);
  return res;
};

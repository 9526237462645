import React from "react";
import Switch from "../subComp/Switch";

function AppSettings_Settings() {
  return (
    <div>
      AppSettings_Settings
      <div className="p-5">
        <Switch label={"Enable Blabla"} initialChecked={true} />
      </div>
      <div className="p-5">
        <Switch label={"Language"} initialChecked={true} />
      </div>
      <div className="p-5">
        <Switch label={"Currency and Units"} initialChecked={true} />
      </div>
      <div className="p-5">
        <Switch label={"Language"} initialChecked={true} />
      </div>
    </div>
  );
}

export default AppSettings_Settings;

import React, { useState } from "react";
import GuestList from "../GuestList";

function EditRoom_GuestList() {
  const [data, setData] = useState([
    { name: "John Doe", email: "john.doe@example.com", role: "Manager" },
    { name: "Jane Smith", email: "jane.smith@example.com", role: "Filter" },
    { name: "Alice Brown", email: "alice.brown@example.com", role: "Guest" },
    { name: "Bob Johnson", email: "bob.johnson@example.com", role: "User" },
  ]);

  const columns = ["Name", "Email", "Role"];

  const updateData = (newData) => {
    setData(newData); // Update the actual data
  };

  return (
    <div>
      EditRoom_GuestList
      <div>
        <GuestList data={data} columns={columns} updateData={updateData} />
      </div>
    </div>
  );
}

export default EditRoom_GuestList;

import React from "react";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "./Icon";
import useMobile from "../useMobile";

function OptionsMenu(props) {
  const { dropObj, color='white' } = props;
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [toggleOptionsMenu, setToggleOptionsMenu] = useState(false);
  const toggleDropdown = () => {
    console.log('test',)
    setToggleOptionsMenu((prev) => !prev);
  };

  console.log('asdad')
  return (
    <div
   
    className={'relative w-[25px]  flex justify-center items-center h-[25px] mr-0.5'}>
      <Icon
        name="options"
        size={13}
        color={color}
        onClick={() => toggleDropdown()}
      />

      {toggleOptionsMenu && (
        <div 
        style={isMobile ?{
          position: "fixed",
          padding: "20px",
          paddingBottom: "40px",
          bottom: "0px",
          borderRadius:'25px 25px 0px 0px',
          background: "white",
          left: "0",
          zIndex: "40000",
          width: "100%",
        }:{  zIndex: "40000"}}
        className={!isMobile ?"absolute mt-7 w-48 bg-white border border-gray-200 rounded-lg shadow-lg  text-black top-0":""}>
          <div style={{ zIndex: 20 }}>
            <ul style={{ zIndex: 20 , color:'black'}}>
              {dropObj?.length > 0 &&
                dropObj?.map((option, index) => {
                  return (
                    <li
                      className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        option.actionFunc();
                        toggleDropdown();
                      }}
                      key={index}
                    >
                      <Icon
                        className=""
                        name={option.iconName}
                        color="black"
                        size={20}
                      />
                      <span className="mx-4">{option.dropOption}</span>
                    </li>
                  );
                })}

            </ul>
          </div>
        </div>
      )}

      {toggleOptionsMenu && (<div
      onClick={()=>toggleDropdown()}
        style={{
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          position: 'fixed',
          // transform: 'none',
          // perspective: 'none',
          filter: 'none',
          zIndex: 10, backgroundColor: 'rgba(0,0,0,0.5)',
        }}
      ></div>)}

    </div>


  );
}

export default OptionsMenu;

const { deleteEventService, deleteRoomService } = require("../services");

// validate Name
const validateName = (name) => {
  return /^[A-Za-z\s]+$/.test(name); //returns true if matched, vaidates for a-z and A-Z and white space
};

// validate Email
const validateEmail = (email) => {
  let res = email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  if (res) return true;
  return false;
};

// validate Password
const validatePassword = (pass) => {
  var regularExpression =
    /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  // minimum 6 chars, maximum 16 chars
  //  (?=.*[0-9]) - Assert a string has at least one number;
  //  (?=.*[!@#$%^&*]) - Assert a string has at least one special character.

  return regularExpression.test(pass);
};

const editEvent = (event, setEditEvent, setTypeBoard, navigate) => {
  setEditEvent(event);
  navigate("/build-events", { state: { event } });
  // setTypeBoard("build-events");
};

const deleteEvent = async (eventId, setEvents) => {
  console.log("deleteEvent");
  console.log(eventId);
  console.log(localStorage.getItem("token"));
  const token = localStorage.getItem("token");
  try {
    const res = await deleteEventService(eventId, token);
    console.log(res);
    if (res.status == 200) {
      console.log("success");
      setEvents((prev) => {
        prev = prev.filter((x) => x.event_id != eventId);
        return [...prev];
      });

      return res.data.message;
    } else {
      console.log("else in deleteEvent");
      return res.data.message;
    }
  } catch (err) {
    console.log("failed in deleteEvent");
    console.log(err);
    return err;
  }
};

const editRoom = (eventOrRoom, event_id, setEvents, setTypeBoard) => {
  console.log("editRoom");
  setTypeBoard("edit-room");

  console.log(eventOrRoom);
};

const deleteRoom = async (room_id, event_id, setEvents) => {
  console.log("deleteRoom");
  console.log(room_id, event_id, setEvents);
  console.log(localStorage.getItem("token"));
  const token = localStorage.getItem("token");
  try {
    const res = await deleteRoomService(room_id, event_id, token);
    console.log(res);
    if (res.status == 200) {
      console.log("success");
      setEvents((prev) => {
        const index = prev.findIndex((x) => x.event_id == event_id);
        if (index !== -1) {
          prev[index].rooms = prev[index].rooms.filter(
            (x) => x.room_id != room_id
          );
        }
        console.log(prev);

        return [...prev];
      });
      return res.data.message;
    } else {
      console.log("else in deleteRoom");
      return res.data.message;
    }
  } catch (err) {
    console.log("failed in deleteRoom");
    console.log(err);
    return err;
  }
};

const copyToClipboard = (link) => {
  console.log(link);
  navigator.clipboard
    .writeText(link)
    .then(() => {
      console.log("Link copied to clipboard!"); // Notify the user
    })
    .catch((err) => {
      console.error("Failed to copy the link: ", err);
    });
};

const shareContent = (link) => {
  const shareData = {
    title: "Check this out!",
    text: "I found this amazing link for you:",
    url: { link }, // The link you want to share
  };

  // Check if the browser supports the Web Share API
  if (navigator.share) {
    navigator
      .share(shareData)
      .then(() => {
        console.log("Content shared successfully!");
      })
      .catch((error) => {
        console.error("Error sharing content: ", error);
      });
  } else {
    alert("Sharing is not supported in your browser.");
  }
};
module.exports = {
  validateName,
  validateEmail,
  validatePassword,
  editEvent,
  deleteEvent,
  editRoom,
  deleteRoom,
  copyToClipboard,
  shareContent,
};

import { useRef, useState, useEffect } from "react";
import { registerUser } from "../services";
import { useNavigate } from "react-router-dom";
import BackButton from "./BackButton";
import useMobile from "./useMobile";
import Icon from "./subComp/Icon";
import InputPassword from "./subComp/InputPassword";
import InpuEmail from "./subComp/InpuEmail";
import InputText from "./subComp/InputText";
import {
  validateName,
  validateEmail,
  validatePassword,
} from "../tools/general";
import Logo from "./subComp/Logo";

export default function Register() {
  const navigate = useNavigate();
  const { isMobile } = useMobile();
  const [msgToUser, setMsgToUser] = useState({ color: "", msg: "" });
  const textRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);

  // NAME management
  const onChangeText = (e) => {
    // console.log("onChangeText: ", e.target.value, validateName(e.target.value));
  };

  const onBlurText = (e) => {
    console.log("onBlurText: ", e.target.value, validateName(e.target.value));
  };

  // MAIL management
  const onChangeMail = (e) => {
    // console.log(
    //   "onChangeMail: ",
    //   e.target.value,
    //   validateEmail(e.target.value)
    // );
  };

  const onBlurMail = (e) => {
    console.log("onBlurEmail: ", e.target.value, validateEmail(e.target.value));
  };

  // PASSWORD management
  const onChangePassword = (e) => {
    // console.log(
    //   "onChangePassword: ",
    //   e.target.value,
    //   validatePassword(e.target.value)
    // );
  };

  const onBlurPassword = (e) => {
    console.log(
      "onBlurPassword: ",
      e.target.value,
      validatePassword(e.target.value)
    );
  };

  // RE PASSWORD management
  const onChangeRePassword = (e) => {
    // console.log(
    //   "onChangeRePassword: ",
    //   e.target.value,
    //   validatePassword(e.target.value)
    // );
  };

  const onBlurRePassword = (e) => {
    console.log(
      "onBlurRePassword: ",
      e.target.value,
      validatePassword(e.target.value)
    );
  };

  // ON SUBMIT
  const onSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password, repassword } = e.target;
    console.log(name.value, email.value, password.value, repassword.value);

    // submit check text
    textRef.current.isValidate = validateName(name.value);
    console.log(textRef.current.isValidate);
    if (!textRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Not a valid Name.",
      });

    // submit check mail
    emailRef.current.isValidate = validateEmail(email.value);
    console.log(emailRef.current.isValidate);
    if (!emailRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Not a valid mail.",
      });

    // submit check passwords mach
    if (repassword.value !== password.value) {
      passwordRef.current.isValidate = false;
      rePasswordRef.current.isValidate = false;
      return setMsgToUser({ color: "red", msg: "Passwords dont match" });
    } else {
      setMsgToUser({ color: "green", msg: "" });
    }

    // submit check password strong
    passwordRef.current.isValidate = validatePassword(password.value);
    console.log(passwordRef.current.isValidate);
    if (!passwordRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Password must have 6-8 chars. at least one number and one special character.",
      });

    // submit check Repassword strong
    rePasswordRef.current.isValidate = validatePassword(repassword.value);
    console.log(rePasswordRef.current.isValidate);
    if (!rePasswordRef.current.isValidate)
      return setMsgToUser({
        color: "red",
        msg: "Password must have 6-8 chars. at least one number and one special character.",
      });

    // pass tests - try to send
    console.log("Try to Register...");

    try {
      const res = await registerUser(name.value, email.value, password.value);
      console.log(res);
      console.log(res.status);
      if (res.status == 200) {
        console.log("success");
        console.log(res.data.data.message);
        setMsgToUser({
          color: "green",
          msg: res.data.data.message,
        });
        navigate("/login");
      } else {
        console.log("failed");
        setMsgToUser({ color: "red", msg: res.data.data.message });
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
      setMsgToUser({
        color: "red",
        msg:
          err.name == "AxiosError"
            ? err.response.data.message
            : "Process failed. Try agian",
      });
    }
  };

  // useEffect(() => {
  //   if (!msgToUser) return;
  //   const to = setTimeout(() => {
  //     setMsgToUser({ color: "", msg: "" });
  //   }, 3000);
  //   return () => clearTimeout(to);
  // }, [msgToUser]);

  return (
    <div className="flex min-h-full flex-1  shadow-lg flex-col justify-center px-6 py-12 lg:px-8">
      {isMobile && <BackButton />}
      <div className="sm:mx-auto sm:w-full sm:max-w-sm flex flex-col justify-center items-center">
        <Logo width={80} height={80} />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Register in to your account
        </h2>
      </div>

      <div className="mt-4 p-[20px] sm:mx-auto sm:w-full sm:max-w-[350px]">
        <form onSubmit={onSubmit} className="space-y-2">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div className="mt-1">
              {/* <input
                id="name"
                name="name"
                type="name"
                required
                autoComplete="name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
              /> */}

              <InputText
                name={"name"}
                textRef={textRef}
                onChange={onChangeText}
                onBlur={onBlurText}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              email
            </label>
            <div>
              <InpuEmail
                name={"email"}
                emailRef={emailRef}
                onChange={onChangeMail}
                onBlur={onBlurMail}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-1">
              <InputPassword
                name={"password"}
                passwordRef={passwordRef}
                onChange={onChangePassword}
                onBlur={onBlurPassword}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="repassword"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                rePassword
              </label>
            </div>
            <InputPassword
              name={"repassword"}
              passwordRef={rePasswordRef}
              onChange={onChangeRePassword}
              onBlur={onBlurRePassword}
            />
          </div>

          <div className="text-sm" style={{ color: msgToUser?.color }}>
            {msgToUser?.msg}
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-primary-600  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            >
              Register
            </button>
          </div>
        </form>

        <p className="mt-1 text-center text-sm text-gray-500">
          Already member?{" "}
          <a
            href="/login"
            className="font-semibold leading-6 text-primary-500 hover:text-primary-500"
          >
            Login
          </a>
        </p>
      </div>
    </div>
  );
}

import { useLocation, useNavigate } from "react-router-dom";
import Icon from "./subComp/Icon";
import useMobile from "./useMobile";
import { useEffect, useState } from "react";

export default function NavBar(props) {
  const {} = props;
  const location = useLocation();
  const { isMobile } = useMobile();
  const navigate = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [zIndexMenu, setZindexMenu] = useState("z-[-1]");
  useEffect(() => {
    let timeout;
    if (toggleMenu) {
      setZindexMenu("z-30");
    } else {
      timeout = setTimeout(() => {
        if (!toggleMenu) {
          setZindexMenu("z-[-1]");
        }
      }, 300);
    }
    return () => clearTimeout(timeout);
  }, [toggleMenu]);
  // console.log()
  return (
    <nav
      className="flex items-center justify-between  px-4 py-2 lg:px-4"
      aria-label="Global"
    >
      <div className="peer flex flex-row justify-center items-center">
        <div className=" w-[48px] h-[48px] flex justify-center items-center">
          <Icon
            name="menu-hamburger"
            color="black"
            size={25}
            onClick={() => {
              console.log("asdasdasd");
              setToggleMenu((prev) => !prev);
            }}
          />
        </div>
        <div className=" w-[48px] h-[48px] flex justify-center items-center">
          <div
            onClick={() => {
              // guset go to /
              // if not guest go to /dashbord
              navigate("/");
            }}
            className="rounded-[50px] w-[30px] flex justify-center items-center  h-[30px] bg-black text-white font-sm"
          >
            <span>EF</span>
          </div>
        </div>
      </div>

      <div className=" lg:flex lg:gap-x-12 pl-12">
        <button
          type="button"
          class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
          id="user-menu-button"
          aria-expanded="false"
          aria-haspopup="true"
          // onClick={() => setlocation.pathname('/profile')}
        >
          <span class="absolute -inset-1.5"></span>
          <span class="sr-only">Open user menu</span>
          <img
            class="h-8 w-8 rounded-full"
            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
        </button>
      </div>

      <div
        className={`w-full h-full  transform  transition-transform  absolute top-0 left-0 ${zIndexMenu}`}
      >
        <div
          style={{
            boxShadow: "2px 0px 8px 3px rgba(0,0,0,0.09)",
            zIndex: 2,
          }}
          className={`righti flex absolute top-0  -left-96 h-full flex-col ${
            isMobile ? "w-8/12" : "w-2/12"
          } bg-white transition-all  ${
            toggleMenu ? "left-0 ease-in" : " -left-96 ease-out"
          }   delay-20 duration-300 `}
        >
          <div className="flex flex-row-reverse mb-[15px] py-4 justify-between w-full items-center px-4">
            <div className="rounded-[50px] w-[30px] flex justify-center items-center  h-[30px] bg-black text-white font-sm">
              <span>EF</span>
            </div>
            <h5 className="p-2 text-center">Menu</h5>

            <Icon
              name="menu-hamburger"
              color="black"
              size={20}
              onClick={() => setToggleMenu((prev) => !prev)}
            />
          </div>

          <div className="flex flex-col h-full relative">
            <div className="">
              {/* home - dashboard*/}
              <div
                onClick={() => navigate("/dashboard")}
                className={`w-[100%]  ${
                  location.pathname !== "/dashboard"
                    ? "hover:bg-primary-100"
                    : ""
                } cursor-pointer  ${
                  location.pathname == "/dashboard"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/dashboard"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Home</span>
                <span>
                  <Icon
                    name="home"
                    color={
                      location.pathname == "/dashboard" ? "white" : "black"
                    }
                  />
                </span>
              </div>
              {/* build-events */}
              <div
                onClick={() => navigate("/build-events")}
                className={`w-[100%]  ${
                  location.pathname !== "/build-events"
                    ? "hover:bg-primary-100"
                    : ""
                }  cursor-pointer  ${
                  location.pathname == "/build-events"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/build-events"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center  ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Create Event</span>
                <span>
                  <Icon
                    name="plus"
                    color={
                      location.pathname == "/build-events" ? "white" : "black"
                    }
                  />
                </span>
              </div>
              {/* Profile */}
              <div
                onClick={() => navigate("/profile")}
                className={`w-[100%]  ${
                  location.pathname !== "/profile" ? "hover:bg-primary-100" : ""
                } cursor-pointer ${
                  location.pathname == "/profile"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/profile"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center   ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Profile</span>
                <span>
                  <Icon
                    name="profile"
                    color={location.pathname == "/profile" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* Settings */}
              <div
                onClick={() => navigate("/app-settings")}
                className={`w-[100%]  ${
                  location.pathname !== "/app-settings"
                    ? "hover:bg-primary-100"
                    : ""
                } cursor-pointer  ${
                  location.pathname == "/app-settings"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/app-settings"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Settings</span>
                <span>
                  <Icon
                    name="settings"
                    color={
                      location.pathname == "/app-settings" ? "white" : "black"
                    }
                  />
                </span>
              </div>
            </div>
            <div className="flex flex-col absolute w-full bottom-0">
              {/* Language */}
              <div
                // onClick={() => navigate("/language")}
                className={`w-[100%]  ${
                  location.pathname !== "/language"
                    ? "hover:bg-primary-100"
                    : ""
                } cursor-pointer  ${
                  location.pathname == "/language"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/language"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Language</span>
                <span>
                  <Icon
                    name="lang"
                    color={location.pathname == "/language" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* //prices */}
              <div
                onClick={() => navigate("/prices")}
                className={`w-[100%]  ${
                  location.pathname !== "/prices" ? "hover:bg-primary-100" : ""
                }  cursor-pointer  ${
                  location.pathname == "/prices"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/prices" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center  ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Price</span>
                <span>
                  <Icon
                    name="prices"
                    color={location.pathname == "/prices" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* About */}
              <div
                // onClick={() => navigate("/about")}
                className={`w-[100%]  ${
                  location.pathname !== "/about" ? "hover:bg-primary-100" : ""
                } cursor-pointer  ${
                  location.pathname == "/about" ? "text-white" : "text-gray-900"
                }  ${
                  location.pathname == "/about" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>About</span>
                <span>
                  <Icon
                    name="about"
                    color={location.pathname == "/about" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* Accessibility */}
              <div
                // onClick={() => navigate("/accessibility")}
                className={`w-[100%]  ${
                  location.pathname !== "/accessibility"
                    ? "hover:bg-primary-100"
                    : ""
                } cursor-pointer  ${
                  location.pathname == "/accessibility"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/accessibility"
                    ? "bg-primary-600"
                    : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Accessibility</span>
                <span>
                  <Icon
                    name="negishut"
                    color={
                      location.pathname == "/accessibility" ? "white" : "black"
                    }
                  />
                </span>
              </div>
              {/* //FAQ */}
              <div
                // onClick={() => navigate("/faq")}
                className={`w-[100%]  ${
                  location.pathname !== "/faq" ? "hover:bg-primary-100" : ""
                } cursor-pointer  ${
                  location.pathname == "/faq" ? "text-white" : "text-gray-900"
                }  ${
                  location.pathname == "/faq" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>FAQ</span>
                <span>
                  <Icon
                    name="question"
                    color={location.pathname == "/faq" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* //Terms */}
              <div
                // onClick={() => navigate("/terms")}
                className={`w-[100%]  ${
                  location.pathname !== "/terms" ? "hover:bg-primary-100" : ""
                } cursor-pointer  ${
                  location.pathname == "/terms" ? "text-white" : "text-gray-900"
                }  ${
                  location.pathname == "/terms" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Terms Of Use</span>
                <span>
                  <Icon
                    name="terms"
                    color={location.pathname == "/terms" ? "white" : "black"}
                  />
                </span>
              </div>
              {/* //Log out */}
              <div
                // onClick={() => navigate("/logout")}
                className={`w-[100%]  ${
                  location.pathname !== "/logout" ? "hover:bg-primary-100" : ""
                } cursor-pointer  ${
                  location.pathname == "/logout"
                    ? "text-white"
                    : "text-gray-900"
                }  ${
                  location.pathname == "/logout" ? "bg-primary-600" : "bg-white"
                } flex flex-row justify-between items-center ${
                  isMobile ? "py-3" : "py-2"
                } px-4 ${!isMobile ? "text-sm font-semibold" : "text-md"} `}
              >
                <span>Log out</span>
                <span>
                  <Icon
                    name="logout"
                    color={location.pathname == "/logout" ? "white" : "black"}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            background: "rgba(0,0,0,0.5)",
            transition: "opacity 0.3s ease, z-index 0.3s ease",
            opacity: toggleMenu ? 1 : 0,
            pointerEvents: toggleMenu ? "auto" : "none",
          }}
          onClick={() => setToggleMenu(false)}
          className={`w-full ${
            !toggleMenu ? "opacity-0 z-[-1]" : ""
          } h-full absolute top-0 left-0`}
        ></div>
      </div>
    </nav>
  );
}

import Container, { ContainerLayout } from "./Container"
import NavBar from "./NavBar"
import InputPassword from "./subComp/InputPassword"
import InputText from "./subComp/InputText"
import useMobile from "./useMobile"

export default function ProfilePage() {
    const { isMobile } = useMobile()
    return (
        <>
         <NavBar/>
        <Container >
        {/* <div className={`${isMobile ? 'py-8 px-4' : 'px-[210px]'} flex flex-row w-[100%] h-[100%] pb-4 pt-2`}> */}
          <ContainerLayout className="py-4">

            <div className="w-1/2">
            <img
                class="h-16 w-16 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
            />

            <div className={`${isMobile ?'w-full':'w-1/2'} h-[50px] flex flex-row gap-4 justify-start items-center`}>
                <div className={"text-black font-bold cursor-pointer text-lg"}>
                    My Profile
                </div>
            </div>

            <div className={`flex gap-4 flex-col ${isMobile ?'w-full':'w-1/2'}`}>
                <div className={`${isMobile ?'w-[100%]' :'w-full'}  flex gap-2 flex-col`}>
                    <label>Email:</label>
                    <InputText value={"avi@gmail.com"} disabled={true} />
                </div>
                <div className={`${isMobile ?'w-[100%]' :'w-full'}  flex gap-2 flex-col`}>
                    <label>Change Password:</label>
                    <InputPassword />
                </div>
                <div className={`${isMobile ?'w-[100%]' :'w-full'}  flex gap-2 flex-col`}>

                    <button className="border-red-600 border-2 px-4  rounded-[50px] border-0 py-1.5 text-red-800 hover:bg-red-700 hover:text-white">
                        delete account
                    </button>
                </div>
            </div>
            </div>
            <div className="w-1/2 h-[20px] flex "></div>
          </ContainerLayout>

        {/* </div> */}
        </Container>

        </>

    )
}
import React, { useEffect, useState } from "react";
import OptionsMenu from "./subComp/OptionsMenu";
import Icon from "./subComp/Icon";

const GuestList = ({ data, updateData }) => {
  const [tableData, setTableData] = useState(data);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query

  // Temporary state for the Add Guest row
  const [newGuest, setNewGuest] = useState({
    name: "",
    email: "",
    role: "User", // Default role
  });

  // Dropdown options for roles
  const roleOptions = [
    { dropOption: "Manager", iconName: "admin" },
    { dropOption: "Filter", iconName: "editor" },
    { dropOption: "Guest", iconName: "guest" },
    { dropOption: "User", iconName: "guest" },
  ];

  useEffect(() => {
    console.log(data);
    return () => {};
  }, [data]);

  // Function to update the role for a specific row
  const handleRoleChange = (newRole, rowIndex) => {
    const updatedData = [...tableData];
    updatedData[rowIndex].role = newRole;
    setTableData(updatedData);
    updateData(updatedData);
  };

  // Function to delete a row
  const handleDeleteRow = (rowIndex) => {
    const updatedData = tableData.filter((_, index) => index !== rowIndex);
    setTableData(updatedData);
    updateData(updatedData);
  };

  // Function to handle editing a cell (Name/Email)
  const handleEditCell = (rowIndex, field, newValue) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][field] = newValue;
    setTableData(updatedData);
    updateData(updatedData);
  };

  // Function to update the Add Guest row inputs
  const handleNewGuestChange = (field, value) => {
    setNewGuest((prev) => ({ ...prev, [field]: value }));
  };

  // Function to add a new user
  const handleAddUser = () => {
    if (!newGuest.name || !newGuest.email) {
      alert("Please fill out both Name and Email before adding.");
      return;
    }

    const updatedData = [...tableData, { ...newGuest }];
    setTableData(updatedData);
    updateData(updatedData);

    // Reset the Add Guest row
    setNewGuest({ name: "", email: "", role: "User" });
  };

  // Filtered data based on search query
  const filteredData = tableData.filter(
    (row) =>
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.role.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // "Search..."
  //   placeholder={<Icon name="search" size={14} color="white" />}

  return (
    <div className="max-w-full h-screen p-8">
      {/* Search Bar */}
      <div className="relative px-4">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
        />
        <div class="absolute inset-y-0 end-0 flex items-center z-20 px-8 text-gray-400 rounded-e-md focus:outline-none focus:text-blue-500 dark:text-neutral-500 dark:focus:text-blue-500">
          <Icon name="search" size={15} />
        </div>
      </div>
      <div className="max-h-full max-w-full p-4">
        <table className="min-w-full table-fixed bg-white border border-gray-300 rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">
                Name
              </th>
              <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">
                Email
              </th>
              <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">
                Role
              </th>
              <th className="text-left py-2 px-4 text-sm font-medium text-gray-700">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className={`${
                  rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white"
                } hover:bg-gray-200`}
              >
                {/* Name Column - Editable */}
                <td
                  className="py-2 px-4 text-sm text-gray-700 border-t w-40"
                  contentEditable
                  suppressContentEditableWarning={true}
                  onBlur={(e) =>
                    handleEditCell(rowIndex, "name", e.target.innerText)
                  }
                >
                  {row.name}
                </td>

                {/* Email Column - Editable */}
                <td
                  className="py-2 px-4 text-sm text-gray-700 border-t w-32"
                  contentEditable
                  suppressContentEditableWarning={true}
                  onBlur={(e) =>
                    handleEditCell(rowIndex, "email", e.target.innerText)
                  }
                >
                  {row.email}
                </td>

                {/* Role Column with Dropdown */}
                <td className="py-2 px-4 text-sm text-gray-700 border-t">
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{row.role}</span>
                    <div className="relative">
                      <OptionsMenu
                        dropObj={roleOptions.map((option) => ({
                          ...option,
                          actionFunc: () =>
                            handleRoleChange(option.dropOption, rowIndex),
                        }))}
                      />
                    </div>
                  </div>
                </td>

                {/* Delete Button */}
                <td className="py-2 px-4 text-sm text-gray-700 border-t">
                  <button
                    onClick={() => handleDeleteRow(rowIndex)}
                    className="text-red-600 hover:text-red-800"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}

            {/* Add Guest Row */}
            <tr className="bg-gray-50 hover:bg-gray-200">
              {/* Name Input */}
              <td className="py-2 px-4 text-sm text-gray-700 border-t w-40">
                <input
                  type="text"
                  placeholder="Name"
                  value={newGuest.name}
                  onChange={(e) => handleNewGuestChange("name", e.target.value)}
                  className="w-full border border-gray-300 rounded px-2 py-1"
                />
              </td>

              {/* Email Input */}
              <td className="py-2 px-4 text-sm text-gray-700 border-t w-32">
                <input
                  type="email"
                  placeholder="Email"
                  value={newGuest.email}
                  onChange={(e) =>
                    handleNewGuestChange("email", e.target.value)
                  }
                  className="w-full border border-gray-300 rounded px-2 py-1"
                />
              </td>

              {/* Role Dropdown */}
              <td className="py-2 px-4 text-sm text-gray-700 border-t">
                <select
                  value={newGuest.role}
                  onChange={(e) => handleNewGuestChange("role", e.target.value)}
                  className="w-full border border-gray-300 rounded px-2 py-1"
                >
                  {roleOptions.map((option) => (
                    <option key={option.dropOption} value={option.dropOption}>
                      {option.dropOption}
                    </option>
                  ))}
                </select>
              </td>

              {/* Add Button */}
              <td className="py-2 px-4 text-sm text-gray-700 border-t">
                <button
                  onClick={handleAddUser}
                  className="text-green-600 hover:text-green-800"
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GuestList;

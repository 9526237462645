import axios from "axios";
import { useEffect, useState } from "react";
import { Grid } from "react-loader-spinner";
import { Navigate, useNavigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  const navigate = useNavigate()
  const [childToggle, setChildToggle] = useState(false)
  //   useEffect(()=>{
  //     const run = async()=>{

  //       try {
  //       const res = await axios.post('/auth/validate_token', {
  //         token:localStorage.getItem("token")
  //       })
  //       console.log('res: ', res)
  //     } catch (error) {
  //         if(error.status == 401){
  //           navigate("/login", {replace:true})
  //         }
  //     }
  //     }
  //     run()
  //   },[])
  useEffect(() => {
    const token = localStorage.getItem('token'); // Replace with your token logic
    if (!token) {
      console.log('login--->', children)
      navigate('/login')
    } else {
      setChildToggle(true)
    }
  }, [])

  // return children;
  return childToggle ? children : 
  <div className="w-full h-full bg-white flex justify-center items-center">

  <Grid
    visible={true}
    height="50"
    width="50"
    color="#de2456"
    ariaLabel="grid-loading"
    radius="12.5"
    wrapperStyle={{}}
    wrapperClass="grid-wrapper"
  />
  </div>
  ;
}

export default ProtectedRoute;
export default function Logo(props) {
    const { onClick, width=30, height=30 } = props
    return (
        <div
            onClick={() => onClick()}
            className={`rounded-[50px] w-[${width}px] flex justify-center items-center  h-[${height}px] bg-black text-white font-[${height}]`}>
            <span>
                EF
            </span>
        </div>
    )
}
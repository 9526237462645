import { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { addNewEvent, getAllEventData } from "../services";
import Row from "./Row";
import Icon from "./subComp/Icon";
import axios from "axios";
import InputText from "./subComp/InputText";
import useMobile from "./useMobile";
import InputDate from "./subComp/InputDate";
import TabsLayout from "./subComp/TabsLayout";
import InputPassword from "./subComp/InputPassword";
import FloatMenu from "./subComp/FloatMenu";
import { useLocation } from "react-router-dom";
import CardRoom from "./CardRoom";
import NavBar from "./NavBar";

export default function BuildEvents(props) {
  console.log(props)
  const location = useLocation()
  console.log(location)
  const [daysForDataBackup, setDaysForDataBackup] = useState("14-days");
  const [totalPrice, setTotalPrice] = useState(0);
  const { isMobile } = useMobile()
  const eventNameRef = useRef()
  const eventLocationRef = useRef('')
  const eventTypeRef = useRef()


  const [roomsTabs, setRoomsTabs] = useState([])
  // const [priceItems, setPriceItems] = useState(0);
  const [priceItems, setPriceItems] = useState([]);
  const [toggleShopCard, setToggleShopCard] = useState(false);
  useEffect(() => {
    try {
      if (location?.state?.event) {
        // call data
        const run = async () => {
          console.log(location.state.event.event_id)
          const res = await getAllEventData(
            location.state.event.event_id,
            localStorage.getItem("token")
          );
          console.log(res);
          if (res.status == 200) {
            // do something
            console.log(res)
            const data = res.data.data
            if(eventNameRef.current &&eventTypeRef.current){
              eventNameRef.current.value = data[0].event_name
              eventTypeRef.current.value = data[0].type
            }

            setRooms((prev) => {
              prev = data.map((p, i) => {
                console.log(p)
                return {
                  id: i,
                  name: p.name,
                  startTime: new Date(p.start_at).toISOString().split('T')[0],
                  endTime: new Date(p.end_at).toISOString().split('T')[0],
                  listGuestFile: null,
                  location:p.location,
                  numGuests: p.max_guests,
                  password: p.password
                }
              })
              return [...prev]
            })
          } else {
            console.log("failed");
            console.log(res);
          }
        };
        run();
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
    }
  }, []);


  const [rooms, setRooms] = useState([
    {
      id: 0,
      name: "room 1",
      startTime: new Date().toISOString().slice(0, 19).replace("T", " "),
      endTime: new Date().toISOString().slice(0, 19).replace("T", " "),
      listGuestFile: null,
      numGuests: 500,
      password: "",
    },
  ]);

  const handleOptionChange = (e) => {
    setDaysForDataBackup(e.target.value);
  };
  const goPayment = async () => {
    const payload = {
      // event: props.event,
      rooms,
    };
    console.log("goPayment");
    // send to server the data
    // const res = await addNewEvent(rooms, target.event_name.value, target.event_location.value, target.event_type.value, target.event_admins.value, localStorage.getItem("token"))
    // if (res.status) {
    //     setTypeBoard('events')
    // }
    // const res = await axios.post("/api/rooms/create_event", payload, {
    //   headers: {
    //     token: localStorage.getItem("token"),
    //   },
    // });
    // console.log(res)
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("goPayment");
    const target = e.target;
    try {
      const res = await addNewEvent(
        rooms,
        target.event_name.value,
        target.event_location.value,
        target.event_type.value,
        target.event_admins.value,
        localStorage.getItem("token")
      );
      if (res.status == 200) {
        console.log("CREATE NEW EVENT");
        // setTypeBoard("events");
      } else {
        console.log("else in BuildEvents");
        console.log(res);
      }
    } catch (err) {
      console.log("failed");
      console.log(err);
    }
  };

  useEffect(() => {
    setPriceItems((prev) => {
      const index = prev.findIndex((p) => p.type == "rooms");
      if (index != -1) {
        prev[index].sum = rooms.length * 5;
      } else {
        prev.push({
          type: "rooms",
          sum: 0,
          count: rooms.length * 3,
        });
      }

      return [...prev];
    });
  }, [rooms]);

  useEffect(() => {
    if (daysForDataBackup !== "14-days") {
      setPriceItems((prev) => {
        let sum = 0;
        const index = prev.findIndex((p) => p.type == "backups");
        if (daysForDataBackup == "month") sum = 5;
        if (daysForDataBackup == "six-month") sum = 26;
        if (daysForDataBackup == "custom") sum = 20;
        if (index != -1) {
          prev[index].sum = sum;
        } else {
          prev.push({
            type: "backups",
            sum: sum,
            count: 1,
          });
        }

        return [...prev];
      });
    }
  }, [daysForDataBackup]);

  useEffect(() => {
    let count = 0;
    for (let i = 0; i < priceItems.length; i++) {
      const element = priceItems[i];
      count += element.sum;
    }
    setTotalPrice(count);
  }, [priceItems]);
  function onChange(e) {
    console.log(e)
    setRoomsTabs(prev => {
      if (prev.length == 0) prev = [e.target.value]
      else prev[0] = e.target.value
      return [...prev]
    })
    setRooms(prev => {
      if (prev.length == 0) prev = [{
        id: 0,
        name: e.target.value,
        startTime: new Date().toISOString().slice(0, 19).replace("T", " "),
        endTime: new Date().toISOString().slice(0, 19).replace("T", " "),
        listGuestFile: null,
        numGuests: 500,
        password: "",
      }]
      else prev[0].name = e.target.value
      return [...prev]
    })

  }

  const menuFloatData = [
    {
      icon: { name: "shop", size: 20 },
      onClick: () => setToggleShopCard(prev => !prev),
      style: "bg-primary-600 text-white"
    }]
  return (
    <>
    <NavBar/>
    <form
      onSubmit={onSubmit}
      className="flex justify-center flex-col items-center w-[100%]"
    >
      
      {isMobile &&
        <FloatMenu buttons={menuFloatData} />
      }
      {/* <div
        style={{zIndex:21}}
       className={`z-10 absolute flex flex-col items-center gap-2 ${!isMobile ? 'bottom-[80px]' : 'bottom-4'} left-8`}>
        <div
          className="w-[45px] shadow-lg flex justify-center items-center rounded-[45px] bg-primary-600 h-[45px]"
          onClick={() => setToggleShopCard(prev => !prev)}
        >
          <Icon name="shop" size={20} color={'white'} />
        </div>
      </div> */}


      <div className={`flex  justify-center ${isMobile ? 'flex-col' : 'flex-row  px-[210px]'} items-start w-[100%] `}>
        <div className={`h-[100%] ${isMobile ? 'px-4 pt-2' : 'pr-[24px]'} w-[100%] h-full flex justify-center overflow-scroll `}>
          <div className={`flex flex-col justify-start items-start w-full   h-[100vh] gap-4`}>
            <div className={`${isMobile ? "" :"pt-8"} flex flex-col gap-4  px-1 w-[100%] `}>
              <div className="w-full h-[150px] bg-gray-300 rounded-[15px]  p-4 flex">
                <div className="w-full h-full border-dashed	border-2 rounded-[13px] flex justify-center items-center border-white">
                  <h4 className="text-base font-semibold px-1 leading-7 text-white">Drag or Upload
                    Event Cover Image</h4>
                </div>
              </div>
              <div className="   w-[100%]">
                <h2 class="text-base font-semibold px-1 leading-7 text-gray-900 ">
                  Event Details
                </h2>

              </div>
            </div>
            <div className="flex text-sm flex-col gap-2  w-[100%]">
              {isMobile
                &&
                <div className="w-full flex flex-row">
                  <div className={`w-full px-1`}>
                    <InputText name="event_name" textRef={eventNameRef}
                      onChange={onChange}
                      placeholder="Event Name" />
                  </div>
                  <div className="w-full flex flex-row">

                    <div className="w-full px-1">
                      <InputText name="event_type" placeholder="Event Type" textRef={eventTypeRef} />
                    </div>
                  </div>
                </div>
              }
              {!isMobile
                &&
                <div className="w-full flex flex-row">
                  <div className={`w-6/12 px-1`}>
                    <InputText name="event_name" onChange={onChange} placeholder="Event Name" textRef={eventNameRef} />
                  </div>
                  <div className="w-1/2 px-1">
                    <InputText name="event_type" placeholder="Event Type" textRef={eventTypeRef} />
                  </div>
                </div>
              }


            </div>
            <div className="w-full p-2 text-sm gap-4 flex flex-col rounded-[5px]">
              <div className="w-[100%] flex  flex-col">
                <div className="w-[100%] flex  flex-row justify-between items-center ">
                  <div className="w-[100%]">
                    <h2 class="text-base font-semibold leading-7 text-gray-900 ">
                      Rooms
                    </h2>

                  </div>

                  <Icon
                    name="plus"
                    size={20}
                    onClick={() => {
                      setRooms((prev) => [
                        ...prev,
                        {
                          id: 0,
                          name: `room ${rooms.length + 1}`,
                          startTime: new Date()
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                          endTime: new Date()
                            .toISOString()
                            .slice(0, 19)
                            .replace("T", " "),
                          listGuestFile: null,
                          numGuests: 500,
                          password: "",
                        },
                      ]);
                    }}
                  />
                </div>
              </div>
              <div className="w-full rounded-[15px] h-full bg-white rounded-[15px] border border-gray-200 shadow">

                <TabsLayout
                  tabs={rooms.map(p => p.name)}
                  size={"sm"}
                >
                  {rooms.map(p => {
                    return <CardRoom room={p}/>
                    // return <div
                    //   className={`w-full p-4 gap-8   flex ${isMobile ? 'flex-col' : 'flex-row'} items-center`}
                    // >

                    //   <div className=" flex flex-col   px-1">

                    //     <div className="flex justify-start items-center w-full p-2">
                    //       <div className="rounded-[15px] bg-gray-300 p-2 border-gray-200 w-[150px] h-[150px]">

                    //         <div className="w-full h-full border-dashed	border-2 rounded-[13px] flex justify-center items-center border-gray-200">
                    //           <h4 className="text-sm font-semibold px-1 leading-2 text-center text-white ">Drag or Upload
                    //             Room Cover Image</h4>
                    //         </div>
                    //       </div>
                    //       {/* <div className="bg-[url(https://via.placeholder.com/150)] rounded-[15px] w-[150px] h-[150px] "></div> */}
                    //     </div>
                    //   </div>
                    //   <div className="flex flex-col gap-4 w-full">
                    //     <div className="w-full flex flex-col gap-2 px-1">
                    //       <h5>Date:</h5>
                    //       <div className="flex flex-row ">
                    //         <div className="w-full px-1">
                    //           <InputDate  name="start_date" />
                    //         </div>
                    //         <div className="w-full px-1" >
                    //           <InputDate name="end_date" />
                    //         </div>
                    //       </div>
                    //     </div>
                    //     <div className="w-full flex flex-col gap-2  px-1">
                    //       <h5>Security:</h5>
                    //       <div>
                    //         <InputPassword name="password" />
                    //       </div>
                    //     </div>
                    //     <div className="w-full flex flex-col gap-2  px-1">

                    //       <InputText name="event_location"
                    //         textRef={eventLocationRef}
                    //         placeholder="Event Location" />
                    //     </div>
                    //   </div>
                    // </div>
                  })}
                </TabsLayout>
              </div>


            </div>

            <div class="border-b pb-[170px] w-[100%] border-gray-900/10 p-2 flex flex-col gap-2
                pb-24">
              <h2 class="text-base font-semibold  text-gray-900 ">Backup</h2>
              <p class=" text-sm  text-gray-600">
                By default the event images save for you for 14 days after the
                events finish.
              </p>

              <div class=" ">
                <fieldset>

                  <div class="flex flex-row gap-4">
                    <div class="relative flex gap-x-3">
                      <div class="flex h-6 items-center">
                        <input
                          id="14-days"
                          name="14-days"
                          type="radio"
                          value="14-days"
                          checked={daysForDataBackup === "14-days"}
                          onChange={handleOptionChange}
                          className="form-radio text-secondary-200"

                        />
                      </div>
                      <div class="text-sm flex flex-col gap-1 ">
                        <label for="14-days" class="font-medium text-gray-900">
                          14 days
                        </label>
                        <p class="text-white text-weight-800 bg-secondary-500 rounded text-center">
                          Free
                        </p>
                      </div>
                    </div>
                    <div class="relative flex gap-x-3 ">
                      <div class="flex h-6 items-center ">
                        <input
                          id="month"
                          name="month"
                          type="radio"
                          value="month"
                          checked={daysForDataBackup === "month"}
                          onChange={handleOptionChange}
                          className="form-radio text-blue-200"
                        />
                      </div>
                      <div class="text-sm flex flex-col gap-1">
                        <label for="month" class="font-medium text-gray-900">
                          1 month
                        </label>
                        <p class="text-gray-500">Extra 5$</p>
                      </div>
                    </div>

                    <div class="relative flex gap-x-3">
                      <div class="flex h-6 items-center">
                        <input
                          id="six-month"
                          name="six-month"
                          type="radio"
                          value="six-month"
                          checked={daysForDataBackup === "six-month"}
                          onChange={handleOptionChange}
                          className="form-radio text-blue-500"
                        />
                      </div>
                      <div class="text-sm flex flex-col gap-1">
                        <label
                          for="six-month"
                          class="font-medium text-gray-900"
                        >
                          6 months
                        </label>
                        <p class="text-gray-500">Extra 26$</p>
                      </div>
                    </div>
                    <div class="relative flex gap-x-3">
                      <div class="flex h-6 items-center">
                        <input
                          id="custom"
                          name="custom"
                          type="radio"
                          value="custom"
                          checked={daysForDataBackup === "custom"}
                          onChange={handleOptionChange}
                          className="form-radio text-blue-500"
                        />
                      </div>
                      <div class="text-sm flex flex-col gap-1">
                        <label for="custom" class="font-medium text-gray-900">
                          Custom
                        </label>
                        <p class="text-gray-500">20$ per month</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

          </div>
        </div>
        {isMobile && toggleShopCard &&
          <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center"
            style={{ zIndex: 10 }}
          >
            <div dir="ltr"
              style={{ zIndex: 11 }}

              className={`max-h-[900px] p-8 ${isMobile ? 'w-full' : 'w-1/2'} z-2 flex justify-center`}>
              <div class=" w-full flex justify-center items-center">
                <div class={`flex px-4 border border-gray-200  shadow   flex justify-start ${isMobile ? 'w-full' : "min-w-[250px]"} flex-col w-[100%] bg-white rounded-[15px]  w-full`}>
                  <div class="flex-1 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <h2
                        class="text-lg font-medium text-gray-900"
                        id="slide-over-title"
                      >
                        Shopping cart
                      </h2>
                    </div>
                    <div class="flex-1 py-6 sm:px-6">
                      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          {priceItems.map((item) => {
                            return <Row item={item} />;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div class="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>${totalPrice}</p>
                    </div>
                    <p class=".5 text-sm text-gray-500">
                      Taxes calculated at checkout.
                    </p>
                    {/* <div class="" onClick={() => goPayment()}> */}
                    <button
                      //   href="#"
                      type="submit"
                      class="flex items-center w-full mt-4 justify-center rounded-md border border-transparent bg-primary-500 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-600"
                    >
                      Checkout
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={() => setToggleShopCard(false)}
              style={{
                background: "rgba(0,0,0,0.5)",
                transition: "opacity 0.8s ease, z-index 0.8s ease",
                opacity: toggleShopCard ? 1 : 0,
                zIndex: 9
              }}
              className="absolute top-0 left-0 w-full h-full z-1"></div>
          </div>
        }
        {!isMobile &&

          <div dir="ltr"
            style={{ zIndex: 11 }}
            className={`max-h-[900px] p-8 ${isMobile ? 'w-full' : 'w-1/2'} z-2 flex justify-center`}>
            <div class=" w-full flex justify-center items-center">
              <div class={`flex px-4 border border-gray-200  shadow   flex justify-start ${isMobile ? 'w-full' : "min-w-[250px]"} flex-col w-[100%] bg-white rounded-[15px]  w-full`}>
                <div class="flex-1 py-6 sm:px-6">
                  <div class="flex items-start justify-between">
                    <h2
                      class="text-lg font-medium text-gray-900"
                      id="slide-over-title"
                    >
                      Shopping cart
                    </h2>
                  </div>
                  <div class="flex-1 py-6 sm:px-6">
                    <div class="flow-root">
                      <ul role="list" class="-my-6 divide-y divide-gray-200">
                        {priceItems.map((item) => {
                          return <Row item={item} />;
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                  <div class="flex justify-between text-base font-medium text-gray-900">
                    <p>Subtotal</p>
                    <p>${totalPrice}</p>
                  </div>
                  <p class=".5 text-sm text-gray-500">
                    Taxes calculated at checkout.
                  </p>
                  {/* <div class="" onClick={() => goPayment()}> */}
                  <button
                    //   href="#"
                    type="submit"
                    class="flex items-center w-full mt-4 justify-center rounded-md border border-transparent bg-primary-500 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-600"
                  >
                    Checkout
                  </button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>

        }

      </div>
    </form >
    </>

  );
}

import React from "react";
import Switch from "../subComp/Switch";
function AppSettings_Account() {
  return (
    <div>
      AppSettings_Account
      <div className="p-5">
        <Switch label={"Privacy"} initialChecked={true} />
      </div>
      <div className="p-5">
        <Switch label={"Security"} initialChecked={true} />
      </div>
    </div>
  );
}

export default AppSettings_Account;

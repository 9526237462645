import { useEffect, useRef, useState } from "react";
import { LuRefreshCw } from "react-icons/lu";
import { IoFlashOutline } from "react-icons/io5";
import { IoIosTimer } from "react-icons/io";
import { addImage } from "../services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import vision from "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3";
import BackButton from "./BackButton";
import useMobile from "./useMobile";
import Container, { ContainerLayout } from "./Container";
import Icon from "./subComp/Icon";
const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;

let constraints = {};
if (typeof window !== "undefined") {
  constraints = {
    video: {
      aspectRatio: { ideal: 9 / 16 },
      facingMode: { ideal: "user" },
      // { width: 320, height: 240 
      width: {
        
        ideal: window ? (window?.orientation === 1 ? 950 : 1920) : null,
      }, // Adjust resolution for performance
      height: {
        ideal: window ? (window?.orientation === 1 ? 1920 : 950) : null,
      },
      focusDistance: { ideal: 0.5 },
      brightness: { ideal: 100 }, // Try adjusting brightness
      contrast: { ideal: 250 }, // Try adjusting contrast
      exposureMode: "manual", // This may not be supported, check capabilities first
      exposureTime: { ideal: 0.025 }, // Exposure in seconds, may not be supported
    },
  };
}

export default function Camera(props) {
  const location = useLocation();
  const { event_id, room_id } = location?.state || {};
  console.log(event_id, room_id);
  const { lastImage, setLastImage } = props;
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const videoRefSec = useRef(null);
  
  const canvasRef = useRef(null);
  const canvasRefOutput = useRef(null);
  const { isMobile } = useMobile();
  const mediaRecorder = useRef(null);
  const settingCamera = useRef(null);
  const recordedChunks = useRef([]);
  const [track, setTrack] = useState(null);
  const [flashEnabled, setFlashEnabled] = useState(false);
  const [mode, setMode] = useState("photo"); // Can be 'photo' or 'video'
  const [typeCamera, setTypeCamera] = useState(["photo", "video", "filters"]);
  const [activeTypeCam, setActiveTypeCam] = useState("video");
  const overlayImage = useRef();
  const videoWidth = 400;
  // const overlayImage = new Image();
  // overlayImage.src = 'mask.png'; // Ensure this path is correct

  const faceLandmarker = useRef(null);
  useEffect(() => {
    overlayImage.current = new Image();
    overlayImage.current.src = `${process.env.PUBLIC_URL}/masks/test.png`;

    overlayImage.current.onload = async () => {
      // Initialize faceLandmarker
      try {
        const filesetResolver = await FilesetResolver.forVisionTasks(
          "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
        );
        faceLandmarker.current = await FaceLandmarker.createFromOptions(
          filesetResolver,
          {
            baseOptions: {
              modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
              delegate: "GPU",
            },
            outputFaceBlendshapes: true,
            runningMode: "VIDEO",
            numFaces: 1,
          }
        );

        // Call predictWebcam only after both faceLandmarker and overlayImage are ready
        predictWebcam(overlayImage.current);
      } catch (error) {
        console.error("Error initializing faceLandmarker:", error);
      }
    };

    overlayImage.current.onerror = () => {
      console.error("Failed to load the overlay image.");
    };
  }, []);

  async function predictWebcam(overlayImage) {
    console.log(settingCamera);
    if (videoRef.current) {

      const radio = videoRef.current.videoHeight / videoRef.current.videoWidth;
      // videoRef.current.style.width = window.innerWidth  + "px";
      // videoRef.current.style.height = window.innerWidth * radio + "px";

      if (canvasRefOutput.current) {
        canvasRefOutput.current.width =
          videoRef.current.getBoundingClientRect().width;
          
        canvasRefOutput.current.height =
        videoRef.current.getBoundingClientRect().height;
          

        canvasRefOutput.current.style.left = `${(window.innerWidth - settingCamera.current?.width < 0
            ? 0
            : window.innerWidth - settingCamera.current?.width) / 2
          }px`;

        const ctx = canvasRefOutput.current.getContext("2d");
        function render() {
          if (
            !faceLandmarker.current ||
            !videoRef.current ||
            !videoRefSec.current ||
            videoRef.current.paused ||
            videoRef.current.ended||
            videoRefSec.current.paused ||
            videoRefSec.current.ended
          )
            return; // Ensure video is playing

          if (!faceLandmarker.current) return; // Ensure faceLandmarker is available

          const startTimeMs = performance.now();
          const results = faceLandmarker.current.detectForVideo(
            videoRef.current,
            startTimeMs
          );

          ctx.clearRect(
            0,
            0,
            canvasRefOutput.current.width,
            canvasRefOutput.current.height
          );

          if (results && results.faceLandmarks.length > 0) {
            const landmarks = results.faceLandmarks[0];
            const leftEyeCorner = landmarks[33];
            const rightEyeCorner = landmarks[263];
            const noseBridge = landmarks[6];

            const leftX = leftEyeCorner.x * canvasRefOutput.current.width;
            const leftY = leftEyeCorner.y * canvasRefOutput.current.height;
            const rightX = rightEyeCorner.x * canvasRefOutput.current.width;
            const rightY = rightEyeCorner.y * canvasRefOutput.current.height;

            const angle = Math.atan2(rightY - leftY, rightX - leftX);
            const glassesWidth =
              Math.abs(rightEyeCorner.x - leftEyeCorner.x) *
              canvasRefOutput.current.width -
              30;
            const glassesHeight =
              glassesWidth * (overlayImage.height / overlayImage.width);

            ctx.save();
            ctx.translate(
              leftX + (rightX - leftX) / 2,
              noseBridge.y * canvasRefOutput.current.height
            );
            ctx.rotate(angle);
            ctx.drawImage(
              overlayImage,
              -glassesWidth * 1.1,
              -glassesHeight * 1.0,
              glassesWidth * 2.2,
              glassesHeight * 2
            );
            ctx.restore();
          }
          window.requestAnimationFrame(render);
        }

        window.requestAnimationFrame(render);
      }
    }

  }

  const resolutions = [
    { width: 7680, height: 4320 },  // 8K UHD
    { width: 5120, height: 2880 },  // 5K
    { width: 4096, height: 2160 },  // 4K DCI (cinematic)
    { width: 3840, height: 2160 },  // 4K UHD
    { width: 2560, height: 1440 },  // QHD (1440p)
    { width: 1920, height: 1080 },
    { width: 1280, height: 720 },
    { width: 1024, height: 768 },
    { width: 800, height: 600 },
    { width: 640, height: 480 },
    { width: 320, height: 240 }
  ];
  
  async function checkResolutions() {
    for (const res of resolutions) {
      try {
        await navigator.mediaDevices.getUserMedia({
          video: {
            width: res.width,
            height: res.height
          }
        });
        console.log(`✅ Supported: ${res.width}x${res.height}`);
      } catch (error) {
        console.log(`❌ Not Supported: ${res.width}x${res.height}`);
      }
    }
  }
  // checkResolutions()

  const openCamera = async () => {
    try {
      await navigator.mediaDevices
        .getUserMedia(
          constraints // Rear camera
        )
        .then((stream) => {
          const videoTrack = stream.getVideoTracks()[0];
          const capabilities = videoTrack.getCapabilities(); // Get supported camera features
          const settings = videoTrack.getSettings(); // Current settings
          console.log(settings);
          settingCamera.current = settings;
          // Example: Adjust focus, zoom
          if (capabilities?.zoom) {
            // videoTrack.applyConstraints({ advanced: [{ zoom: 0.2 }] });  // Set zoom level
          }
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoRefSec.current.srcObject = stream
            setTrack(stream.getVideoTracks()[0]);
            // videoRef.current.addEventListener("loadeddata", predictWebcam);

            videoRef.current.onloadedmetadata = () => {
              predictWebcam(overlayImage.current); // Start predicting landmarks once the video metadata is loaded
            };
          }
          // console.log('Camera Exposure Settings:', settings);
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const enableFlash = async () => {
    if (track && track.getCapabilities().torch) {
      try {
        await track.applyConstraints({
          advanced: [{ torch: true }],
        });
        setFlashEnabled(true);
      } catch (error) {
        console.error("Error enabling flash:", error);
      }
    } else {
      // console.log("Flashlight not supported on this device");
    }
  };
  const disableFlash = () => {
    if (track) {
      track.stop();
      setFlashEnabled(false);
    }
  };

  const takeSnapshot = async () => {
    if (mode == "photo") {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      context.save();
      context.scale(-1, 1);
      context.drawImage(videoRef.current, -canvas.width, 0);
      context.restore();
      setLastImage(canvas.toDataURL("image/png"));
      navigate(`/edit`, {
        state: {
          event_id,
          room_id,
        },
      });
    } else if (mode == "video") {
      startRecording(videoRef.current.src);
    }
  };

  const startRecording = (stream) => {
    mediaRecorder.current = new MediaRecorder(stream);

    // Event handler for data available event (chunks of recorded data)
    mediaRecorder.current.ondataavailable = function (event) {
      if (event.data && event.data.size > 0) {
        recordedChunks.current.push(event.data);
      }
    };

    // Start recording
    mediaRecorder.current.start();

    // You can stop recording after a certain time
    setTimeout(() => {
      mediaRecorder.current.stop();
    }, 5000); // Stop after 5 seconds, for example

    mediaRecorder.current.onstop = function () {
      const blob = new Blob(recordedChunks.current, {
        type: "video/webm",
      });

      // Create a URL for the recorded video
      const videoURL = URL.createObjectURL(blob);

      // Display the recorded video on the page
      const recordedVideo = document.querySelector("#recordedVideo");
      videoRef.current.src = videoURL;

      // Optionally, download the video as a file
      const downloadLink = document.createElement("a");
      downloadLink.href = videoURL;
      downloadLink.download = "recorded-video.webm";
      downloadLink.textContent = "Download Video";
      document.body.appendChild(downloadLink);
    };
  };
  const flipCamera = async () => {
    const newFacingMode =
      track.getSettings().facingMode === "user" ? "environment" : "user";
    await openCameraWithFacingMode("user");
  };

  const openCameraWithFacingMode = async (facingMode) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      setTrack(stream.getVideoTracks()[0]);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  useEffect(() => {
    openCamera();
    return () => {
      disableFlash();
    };
  }, []);
  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div className="flex flex-col h-[100vh] w-[100%] ">
        <div
          style={{ zIndex: 1111 }}
          className="flex flex-row justify-center items-center   w-full h-[80px] py-2"
        >
          <h1 className="text-white drop-shadow-lg text-xl">Avi and lital Wedding</h1>

          {/* <div className="w-full h-full drop-shadow-lg  flex justify-center items-center">
            {isMobile && <BackButton color={"white"} />}
          </div>
          <div className="w-full h-full drop-shadow-lg  flex justify-center items-center">
            <IoFlashOutline color="white" size={20} />
          </div>
          <div className="w-full h-full drop-shadow-lg   flex justify-center items-center">
            <IoIosTimer color="white" size={20} />
          </div> */}
        </div>
        <div className="absolute bottom-0 left-0 z-30 justify-center items-center px-4 h-full flex flex-col gap-8">
          <div className="flex z-50 flex-col justify-center items-center gap-8">
            <div className="w-full h-full drop-shadow-[0_5px_2px_rgba(0,0,0,0.4)] flex justify-center items-center">
              <Icon name="timer" color="white" size={35} />
            </div>
            <div className="w-full h-full drop-shadow-lg  drop-shadow-[0_5px_2px_rgba(0,0,0,0.4)] flex justify-center items-center">
              <Icon name="flash" color="white" size={35} />
            </div>
            <div className="w-full h-full drop-shadow-[0_5px_2px_rgba(0,0,0,0.4)] drop-shadow-lg   flex justify-center items-center">

              <Icon
                name="aspect-ratio" color="white" size={35} />
            </div>
          </div>
        </div>
        <div className="h-full w-full z-1">
          <div
            // style={{ zIndex:  }}
            className="fixed top-0 z-10 left-0 w-full h-full flex justify-start"
          >
            {/*  <div className="w-full h-full flex flex-row justify-evenly">
              <div className="h-full bg-gray-100 w-[1px]"></div>
              <div className="h-full bg-gray-100 w-[1px]"></div>
            </div>
            <div className=" fixed top-0 left-0 w-full flex flex-col justify-evenly items-center h-full">
              <div className="bg-gray-100 h-[1px] w-full"></div>{" "}
              <div className="bg-gray-100  h-[1px] w-full"></div>{" "}
            </div> */}

          </div>          <div
            style={{ zIndex: 119 }}
            className="h-full w-full fixed top-0 left-0 "
          ></div>
          <canvas
            ref={canvasRefOutput}
            className="output_canvas "
            id="output_canvas"
            style={{
              position: "absolute",
              zIndex: 1000,
              left: 0,
              top: 0,
              transform: "scaleX(-1)",
            }}
          ></canvas>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              filter:"contrast(1.3)",

              transform: "scaleX(-1)",
              width: "100%", // Fill the width of the container
              height: "100vh", // Fill the height of the container
              // width: "1920px", // Fill the width of the container
              // height: "1080px", // Fill the height of the container
              objectFit: "contain", // Maintain aspect ratio without distortion
              // position: "absolute",
              // top: 0,
              // left: 0,
              // zIndex: 1,
              // WebkitClipPath: `rect(5px 5px ${window.innerWidth - 10}px ${window.innerHeight - 10}px round 20%)`,
              // clipPath: "path('M 0 800 L 0,75 A 5,5 0,0,1 150,75 L 200 200 z')",
              clipPath: `xywh(8px 70px ${window.innerWidth - 16}px ${window.screen.height - 362}px round 25px)`,
              // clipPath: `rect(5px 5px ${window.innerWidth - 10}px ${window.innerHeight - 10}px round 20%)`,
              // filter:'contrast(1.0) brightness(1.0)',
              backgroundColor: "#141c27", // Ensure background is black for letterboxing effect
            }}
            className="absolute top-0 inset-0 w-full h-full bg-cover bg-center z-20  rounded-lg "
            // className="absolute top-0 inset-0 w-full h-full bg-cover bg-center z-20  rounded-lg [clip-path:url(#mask-camera-event)]"
          />
          <video
            ref={videoRefSec}
            autoPlay
            playsInline
            style={{
              transform: "scaleX(-1)",
              width: "100%", // Fill the width of the container
              height: "100vh", // Fill the height of the container
              // width: "1920px", // Fill the width of the container
              // height: "1080px", // Fill the height of the container
              objectFit: "contain", // Maintain aspect ratio without distortion
              // position: "absolute",
              // top: 20,
              // left: 0,
              // zIndex: 1,
              // filter:'contrast(1.0) brightness(1.0)',
              backgroundColor: "#141c27", // Ensure background is black for letterboxing effect
            }}
            className="absolute inset-0 w-full  h-full bg-cover bg-center border border-2 border-white blur  opacity-40  backdrop-black bg-black brigthness-0 z-10 "
          />
          {/* <video
            ref={videoRef}
            autoPlay
            playsInline
            style={{
              // transform: "scaleX(-1)",
              // width: "100%", // Fill the width of the container
              // height: "100vh", // Fill the height of the container
              // width: "1920px", // Fill the width of the container
              // height: "1080px", // Fill the height of the container
              // objectFit: "contain", // Maintain aspect ratio without distortion
              // position: "absolute",
              // top: 0,
              // left: 0,
              // zIndex: 1,
              // // filter:'contrast(1.0) brightness(1.0)',
              // backgroundColor: "#141c27", // Ensure background is black for letterboxing effect
            }}
            className="bg-black absolute top-0 left-0 w-full h-full"
          />
           */}

          {/* <img src="./test2.jpg" height={2220} width={2000} */}
          {/* <div class="absolute inset-0 w-full h-full bg-cover bg-center z-20  rounded-lg [clip-path:url(#mask)]" style={{ backgroundImage: "url('test2.jpg')" }}></div> */}
          {/* <div class="absolute inset-0 w-full  h-full bg-cover bg-center border border-2 border-white blur  opacity-40  backdrop-black bg-black brigthness-0 z-10" style={{ backgroundImage: "url('test2.jpg')" }}></div> */}
          <div className="bg-black absolute top-0 left-0 w-full h-full"></div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "hidden" }}>
            <defs>
              <clipPath id="mask-camera-event" >
                <rect
                  x="8px"
                  y="70px"
                  width={`${window.innerWidth - 16}px`}
                  height={`${window.innerHeight - 250}px`}
                  rx="16px"
                  ry="16px"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg> */}
          {/* className="absolute top-0 left-0 w-full h-full bg-cover object-cover"/> */}

          {/* <div 
  class="mask-star [clip-path:polygon(0%_0%,0%_80%,80%_80%, 80%_0%, 0%_0%)] absolute inset-0 bg-black opacity-70"
  // style={{maskImage: "linear-gradient(to bottom, transparent 25%, black 100%);", WebkitMaskImage: "linear-gradient(to bottom, transparent 25%, black 100%);"}}
></div> */}
          {/* <canvas
          // width={1920}
          // height={1920}
          ref={canvasRef}
          style={{ display: "none", transform: "scaleX(-1)" }}
          // style={{width:1900, height:1000}}
          /> */}
        </div>
        {/* <div className="bg-red-800 h-[40px] w-full relative"> */}

        {/* <div className="absolute "></div> */}
        {/* </div> */}
        {/* //   */}

        <div
          style={{ zIndex: 11112 }}
          className="absolute bottom-0
   left-0 w-full">

          <div
            style={{ zIndex: 1111 }}
            className="flex flex-row justify-center items-center text-gray-900  z-2 gap-4 h-[80px] w-full  py-4"

          >
            {typeCamera.map((type, indexType) => {
              return (
                <div
                  key={`cammera-${indexType}`}
                  onClick={() => {
                    setActiveTypeCam(type);
                    setMode(type);
                    setTypeCamera((prev) => {
                      const indexCurrent = Math.floor(prev.length / 2);
                      const pushIndex = indexType;
                      const distance = indexCurrent - pushIndex; // 2
                      if (distance > 0) {
                        for (let i = 0; i < distance + 1; i++) {
                          const element = prev.pop();
                          prev.unshift(element);
                        }
                      } else {
                        for (let i = 0; i < distance * -1 + 1; i++) {
                          const element = prev.shift();
                          prev.push(element);
                        }
                      }
                      return [...prev];
                    });
                  }}
                  className={`${activeTypeCam == type ? "p-2" : "p-1"} ${activeTypeCam == type ? "text-md" : "text-sm"
                    } rounded-[25px] ${activeTypeCam == type ? "px-5" : "px-2"} ${activeTypeCam == type ? "bg-primary-600  bg-opacity-10" : ""
                    }  text-size-sm text-white`}
                >
                  {type}
                </div>
              );
            })}
          </div>
          <div
            style={{ zIndex: 1111 }}
            className="flex flex-row  gap-12  w-full  pb-[70px] h-[200px] flex flex-row justify-center  items-center"
          >
            <div className=" h-full x  flex justify-center items-center gap-2">
              {lastImage ? (
                <div
                  style={{
                    transform: "scaleX(-1)",
                    backgroundImage: `url(${lastImage})`,
                    backgroundSize: "cover", // Ensures the image covers the element, might crop the image
                    backgroundPosition: "center", // Ensures the image is centered
                    backgroundRepeat: "no-repeat", // Prevents repeating the image
                    width: "50px", // Fixed width for the div
                    height: "50px", // Fixed height for the div
                    borderRadius: "50%", // Makes the div a perfect circle
                    overflow: "hidden", // Ensures no content spills out of the circular div
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    transform: "scaleX(-1)",
                    background: `white`,
                    backgroundSize: "cover", // Ensures the image covers the element, might crop the image
                    backgroundPosition: "center", // Ensures the image is centered
                    backgroundRepeat: "no-repeat", // Prevents repeating the image
                    width: "50px", // Fixed width for the div
                    height: "50px", // Fixed height for the div
                    borderRadius: "50%", // Makes the div a perfect circle
                    overflow: "hidden", // Ensures no content spills out of the circular div
                  }}
                ></div>
              )}
            </div>

            <div
              // style={{ position: 'absolute', bottom: 0, left: '25%', zIndex: 555555 }}
              className=" h-full drop-shadow-lg flex justify-center items-center"
            >
              <svg
                onClick={() => takeSnapshot()}
                width="80"
                height="80"
                viewBox="0 0 100 100"
                className="drop-shadow-lg "
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="50" cy="50" r="50" fill="white" />
              </svg>
            </div>

            <div className=" h-full w-[60px]  drop-shadow-lg  flex justify-center items-center">
              <LuRefreshCw color={"white"} className="drop-shadow-lg" size={30} />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

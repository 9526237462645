import { useEffect, useState } from "react";
import { getInsideRoom } from "../services";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import InputText from "./subComp/InputText";
import Icon from "./subComp/Icon";

export default function EntryFeed() {
  const { event_id, room_id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const onSubmit = async (e) => {
    e.preventDefault();
    // navigate(`/events/${event_id}/${room_id}/feed`, { userId:"test"})
    try {
      const res = await getInsideRoom(
        e.target.name.value,
        room_id,
        localStorage.getItem("token")
      );
      if (res.status == 200) {
        localStorage.setItem("token", res.data.data.token);
        navigate(`/events/${event_id}/${room_id}/feed`, {
          state: { userId: res.data.data.userId },
        });
      } else {
        console.log("else in getInsideRoom in EntryFeed.js");
        console.log(res);
      }
    } catch (error) {
      setError("There is problem with the room");
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 8000);
  }, [error]);
  return (
    <div
      className="w-[100%] relative leading-7 gap-4 h-[100vh] bg-gray-800 flex flex-col justify-center items-center  ">
      {error && (
        <div className="absolute top-10 w-[80%] p-4 bg-red-400 text-white rounded-[5px]">
          {error}
        </div>
      )}
      <h1 className="text-7xl z-10 text-white">Welcome</h1>
      <h1 className="text-2xl z-10 text-white">Wedding Avi And Lital</h1>
      <div className="z-20 absolute text-white flex justify-center items-center flex-col gap-2 bottom-[100px] ">
        <Icon name="upload-cloud" color="white" size={70} />
        <div>Upload Your Memories</div>
      </div>
      
      <form
        className="w-full z-20 p-4 flex flex-col justify-center items-center  rounded-[25px] flex flex-col"
        onSubmit={onSubmit}
      >
        <div className="w-3/4 max-w-[300px] flex flex-col gap-4">
          <InputText name="name" placeholder="Insert Name" />
          {/* <input placeholder="insert name" name="name" /> */}
          <button className="p-2 py-2 rounded-[10px] bg-primary-500 w-full text-white" type="submit">get in</button>
        </div>
      </form>
      <img
        style={{ filter: "brightness(0.5) blur(6px)" }}
        className="z-1 h-full w-full blur-sm bg-cover bg-center bg-no-repeat backdrop-blur object-cover w-auto absolute top-0 left-0"
        src={'../../test2.jpg'}
      />
    </div>
  );
}
